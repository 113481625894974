import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import ReactPlayer from "react-player";
import { parseUrlFromModel } from "../../../helpers/asset";
import { updateTimeStampAsyncThunk } from "../../../redux/pagesSlices/timeStampSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { colorslist } from "../../../constants";

const TimeStampUpdateModel = ({
  isModalOpen,
  closeModal,
  content,
  handlePlayerReady,
  handleProgress,
  playerRef,
  timeRange,
  setTimeRange,
  timeStringToSeconds,
  parseTime,
  timeStamps,
  updateTimeStamps,
  setUpdateTimeStamps
}) => {
  console.log("🚀 ~ timeRange:", parseTime(timeRange.startTime));
  // console.log("🚀 ~ timeStamps:", timeStamps);
  const secondsToTimeString = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  // Function to handle changes in start time and end time
  const handleChangeTimeRange = (field, value) => {
    const formatTimeWithColon = (time) =>
      time
        .replace(/[^0-9]/g, "")
        .slice(0, 6)
        .replace(/(\d{2})(?=\d)/g, "$1:");

    const newTimeRange = { ...timeRange };

    if (field === "startTime" || field === "endTime") {
      value = formatTimeWithColon(value);
      playerRef.current.seekTo(
        parseTime(value) / playerRef.current.getDuration()
      );
    }

    if (
      field === "endTime" &&
      parseTime(value) > playerRef.current.getDuration()
    ) {
      value = secondsToTimeString(playerRef.current.getDuration());
    }

    newTimeRange[field] = value; // Update the specific field in the copied object
    setTimeRange(newTimeRange); // Set the state with the updated object
  };

  

  // Function to handle key press events for incrementing or decrementing time
  const handleKeyPress = (event, field) => {
    const increment = 1; // You can change this increment as desired
    let newValue = timeRange[field];
    if (event.key === "ArrowUp") {
      newValue = increaseTime(newValue, increment);
    } else if (event.key === "ArrowDown") {
      newValue = decreaseTime(newValue, increment);
    }
    handleChangeTimeRange(field, newValue);
  };

  const increaseTime = (timeString, increment) => {
    const timeArray = timeString.split(":");
    let [hours, minutes, seconds] = timeArray.map(Number);

    seconds += increment;
    if (seconds >= 60) {
      seconds = 0;
      minutes++;
    }
    if (minutes >= 60) {
      minutes = 0;
      hours++;
    }
    hours %= 24;

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  const decreaseTime = (timeString, increment) => {
    const timeArray = timeString.split(":");
    let [hours, minutes, seconds] = timeArray.map(Number);

    seconds -= increment;
    if (seconds < 0) {
      seconds = 59;
      minutes--;
    }
    if (minutes < 0) {
      minutes = 59;
      hours--;
    }
    if (hours < 0) {
      hours = 23;
    }

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  const d = useDispatch();
  const handleFormSubmit = async (data, { resetForm }) => {
    try {
      d(
        updateTimeStampAsyncThunk({
          id: timeRange.id,
          data: {
            startTime: timeRange?.startTime,
            endTime: timeRange?.endTime,
            name: timeRange?.name,
            status: timeRange?.status,
            description: timeRange?.description,
            contentId: content?.id,
          }})
        )

          // Update adjacent timestamps


          console.log('creating index and updated timestamps')
          const index = timeStamps.findIndex(timestamp => timestamp.id === timeRange.id);
          const updatedTimeStamps = [...timeStamps];
          console.log("Index No.", index)

          if (index > 0 && !parseTime(updatedTimeStamps[index-1].endTime) <= 1 ) {
            const endTimeInSeconds = parseTime(timeRange.startTime) - 1;
            const endTimeToBeSent = secondsToTimeString(endTimeInSeconds)
            d(
              updateTimeStampAsyncThunk({
                id: updatedTimeStamps[index-1].id,
                data: {
                  startTime: updatedTimeStamps[index-1]?.startTime,
                  endTime: endTimeToBeSent,
                  name: updatedTimeStamps[index-1]?.name,
                  status: updatedTimeStamps[index-1]?.status,
                  description: updatedTimeStamps[index-1]?.description,
                  contentId: content?.id,
                }
            // updatedTimeStamps[index - 1].endTime = timeRange.startTime;
            })
            )}

            console.log('comparing for the next entry', `${ updatedTimeStamps[index+1] ? `${updatedTimeStamps[index + 1].startTime}` : `Next index not present`}`, timeRange.endTime)
            // Update start time of next timestamp
            if (index < timeStamps.length - 1) {
              // updatedTimeStamps[index + 1].startTime = timeRange.endTime;
              const startTimeInSeconds = parseTime(timeRange.endTime) + 1;
              const startTimeToBeSent = secondsToTimeString(startTimeInSeconds)
              d(
                updateTimeStampAsyncThunk({
                  id: updatedTimeStamps[index+1].id,
                  data: {
                    startTime: startTimeToBeSent,
                    endTime: updatedTimeStamps[index+1]?.startTime,
                    name: updatedTimeStamps[index+1]?.name,
                    status: updatedTimeStamps[index+1]?.status,
                    description: updatedTimeStamps[index+1]?.description,
                    contentId: content?.id,
                  }}
                ))
            }

                closeModal();
                setTimeRange([
                  { startTime: "", endTime: "", name: "", status: "", description: "" },
                ]);
                resetForm();
                toast.success("All TimeStamps Updated Successfully!", {
                  autoClose: false,
                });
              
              
              
              

        } catch (error) {
      console.error("Error updating timestamps:", error);
      // Handle error here
    }
  };

  // Function to calculate background position and width based on time range
  const calculateBackgroundStyle = (timeRange) => {
    const startTime = parseTime(timeRange.startTime);
    const endTime = parseTime(timeRange.endTime);

    const duration = playerRef?.current?.getDuration() || 1; // Default to 1 to avoid division by zero
    const left = (startTime / duration) * 100;
    const width = ((endTime - startTime) / duration) * 100;
    return { left: `${left}%`, width: `${width}%` };
  };

  const handlePlayerReady1 = () => {
    return playerRef.current.seekTo(timeStringToSeconds(timeRange?.startTime));
  };
  return (
    <Modal show={isModalOpen} onHide={closeModal}>
      <Modal.Body className="Video-model">
        <Formik initialValues={{}} onSubmit={handleFormSubmit}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className="flex-box-vido-input mb-3 mt-3">
                <div className="">
                  {/* // i want to trim player content according to timeRange startTime and endTime and then play it */}
                  <ReactPlayer
                    ref={playerRef}
                    url={parseUrlFromModel(content)}
                    width="100%"
                    height="100%"
                    controls={true}
                    playing={true}
                    className="logo_img"
                    onPlay={handlePlayerReady1}
                    onProgress={handleProgress}
                    autoPlay={true}
                    volume={0.1}
                    pip={true}
                  />

                  <div
                    style={{
                      width: "93%",
                      position: "relative",
                      left: "15px",
                      bottom: "32px",
                      background: "black",
                      height: "5px",
                    }}
                  >
                    {timeStamps.map((timestamp, index) => (
                      <div
                        key={index}
                        style={{
                          position: "absolute",
                          background:
                            timeRange.startTime && timeRange?.endTime
                              ? colorslist[index % colorslist.length]
                              : "transparent",
                          ...calculateBackgroundStyle(timeRange),
                          height: "5px",
                          cursor: "pointer",
                        }}
                      ></div>
                    ))}
                  </div>

                  <label>
                    <h5>CH Name</h5>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Your chapter Name"
                    value={timeRange?.name}
                    onChange={(e) =>
                      handleChangeTimeRange("name", e.target.value)
                    }
                  />
                </div>

                <div className="">
                  <label>
                    <h5>Status</h5>
                  </label>

                  <div className="checkbox-flex-box">
                    <label htmlFor={`publish`} className="mr-2">
                      Publish
                      <input
                        type="checkbox"
                        id={`publish`}
                        checked={timeRange.status === "publish"}
                        onChange={(e) =>
                          handleChangeTimeRange(
                            "status",
                            e.target.checked ? "publish" : "hide"
                          )
                        }
                      />
                    </label>
                    <label htmlFor={`hide`} className="mr-2">
                      Hide/Play
                      <input
                        type="checkbox"
                        id={`hide`}
                        checked={timeRange.status === "hide"}
                        onChange={(e) =>
                          handleChangeTimeRange(
                            "status",
                            e.target.checked ? "hide" : "publish"
                          )
                        }
                      />
                    </label>
                    <label htmlFor={`forcePlay`}>
                      Force Hide
                      <input
                        type="checkbox"
                        id={`forcePlay`}
                        checked={timeRange.status === "force play"}
                        onChange={(e) =>
                          handleChangeTimeRange(
                            "status",
                            e.target.checked ? "force play" : "publish"
                          )
                        }
                      />
                    </label>
                  </div>
                </div>

                {/* Time range fields */}
                <div className="">
                  <label>
                    <h5>Start Time</h5>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    pattern="[0-9]{2}:[0-9]{2}:[0-9]{2}"
                    placeholder="hh:mm:ss"
                    value={timeRange.startTime}
                    onChange={(e) =>
                      handleChangeTimeRange("startTime", e.target.value)
                    }
                    onKeyDown={(e) => handleKeyPress(e, "startTime")}
                  />
                </div>
                <div className="">
                  <label>
                    <h5>End Time</h5>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    pattern="[0-9]{2}:[0-9]{2}:[0-9]{2}"
                    placeholder="hh:mm:ss"
                    value={timeRange.endTime}
                    onChange={(e) =>
                      handleChangeTimeRange("endTime", e.target.value)
                    }
                    onKeyDown={(e) => handleKeyPress(e, "endTime")}
                  />
                </div>

                <div className="">
                  <label className="mt-4">
                    <h5>CH Description</h5>
                  </label>
                  <textarea
                    type="text"
                    cols={30}
                    rows={5}
                    className="form-control"
                    placeholder="Enter description here"
                    value={timeRange?.description}
                    onChange={(e) =>
                      handleChangeTimeRange("description", e.target.value)
                    }
                  />
                </div>
              </div>

              <button type="submit" className="Update-btn">
                Update
              </button>
            </form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default TimeStampUpdateModel;
