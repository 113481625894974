import Accordion from "react-bootstrap/Accordion";
import React, { useEffect, useRef, useState } from "react";
import {
  createPerformerAsyncThunk,
  deletePerformerAsyncThunk,
  getPerformersAsyncThunk,
  updatePerformerAsyncThunk,
} from "../redux/pagesSlices/performerSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "./perfomer.css";
import { parseUrlFromModel } from "../helpers/asset";
import { Link } from "react-router-dom";

const PerfomerList = () => {
  const dispatch = useDispatch();

  const [newPerformerName, setNewPerformerName] = useState("");

  const performers = useSelector(
    (state) => state?.performer?.performer?.results
  );

  console.log("The performers are:", performers);

  console.log("The updated Name value is:");

  useEffect(() => {
    if (performers)
      dispatch(
        getPerformersAsyncThunk({
          populate: "content",
          limit: 100,
        })
      );
  }, []);

  const inputRefs = useRef([]);

  const handleSubmit = (id, index, event) => {
    event.preventDefault();
    const updatedName = inputRefs.current[index].value;
    onPerformerNameUpdate(id, updatedName);
  };

  const onPerformerNameUpdate = (id, name) => {
    try {
      dispatch(
        updatePerformerAsyncThunk({
          data: {
            id,
            name,
          },
        })
      );

      toast.success("Performer Details Updated Successfully!");
    } catch (error) {
      toast.error("Error updating Performer details.");
    }
  };

  const deletePerformer = (id) => {
    try {
      dispatch(
        deletePerformerAsyncThunk({
          data: {
            id,
          },
        })
      );

      toast.success("Performer deleted successfully!");
    } catch (error) {
      toast.error("Error deleting Performer details.");
    }
  };

  const submitCreatePerformer = (event) => {
    event.preventDefault();
    try {
      dispatch(
        createPerformerAsyncThunk({
          data: {
            name: newPerformerName,
          },
        })
      );

      toast.success("Performer created successfully!");
      setNewPerformerName("");
    } catch (error) {
      toast.error("Error encountered while creating Performer.");
    }
  };

  return (
    <div className="performers-page">
      <div className="performers-list-container">
        {/* <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Performers List</Accordion.Header>
            <Accordion.Body> */}
        <>
          <div className="assets-table-body">
            <div className="table-row">
              <div className="table-data">
                <b>Performer Sr</b>
              </div>

              <div className="table-data">
                <b>Image</b>
              </div>

              <div className="table-data">
                <b>Name</b>
              </div>

              <div className="table-data">
                <b>Desc..</b>
              </div>

              <div className="table-data">
                <b>Fb</b>
              </div>

              <div className="table-data">
                <b>Insta</b>
              </div>

              <div className="table-data">
                <b>Youtube</b>
              </div>

              <div className="table-data">
                <b>Bio</b>
              </div>

              {/* <div className="table-data">
                <b>Update Name</b>
              </div> */}

              <div className="table-data">
                <b>Delete</b>
              </div>
            </div>

            {performers &&
              performers?.map((performer, index) => (
                <Link
                  className="table-row performer-dropdown-row"
                  to={`/performer/${performer?.id}`}
                  key={performer.id}
                >
                  <div className="table-data">
                    <img
                      style={{
                        height: "80px",
                        width: "80px",
                        cursor: "pointer",
                        borderRadius: "50%",
                      }}
                      src={
                        performer.content
                          ? parseUrlFromModel(performer.content)
                          : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                      }
                      alt=""
                    />
                  </div>

                  <div className="table-data">
                    {performer?.sr ? performer.sr : "N/A"}
                  </div>

                  <div className="table-data">
                    {performer?.name
                      ? performer.name.length > 15
                        ? `${performer.name.slice(0, 15)}...`
                        : performer.name
                      : "N/A"}
                  </div>

                  <div className="table-data">
                    {performer?.description
                      ? performer.description.length > 15
                        ? `${performer.description.slice(0, 15)}...`
                        : performer.description
                      : "N/A"}
                  </div>

                  <div className="table-data">
                    {performer?.fb
                      ? performer.fb.length > 15
                        ? `${performer.fb.slice(0, 15)}...`
                        : performer.fb
                      : "N/A"}
                  </div>

                  <div className="table-data">
                    {performer?.instagram
                      ? performer.instagram.length > 15
                        ? `${performer.instagram.slice(0, 15)}...`
                        : performer.instagram
                      : "N/A"}
                  </div>

                  <div className="table-data">
                    {performer?.youtube
                      ? performer.youtube.length > 15
                        ? `${performer.youtube.slice(0, 15)}...`
                        : performer.youtube
                      : "N/A"}
                  </div>

                  <div className="table-data">
                    {performer?.bio
                      ? performer.bio.length > 15
                        ? `${performer.bio.slice(0, 15)}...`
                        : performer.bio
                      : "N/A"}
                  </div>

                  <hr />
                  {/* <div className="table-data">
                  <form
                    key={performer.id}
                    onSubmit={(event) =>
                      handleSubmit(performer.id, index, event)
                    }
                    className="performer-data-form"
                  >
                    <div>
                      <input
                        type="text"
                        className="update-performer-name-input"
                        defaultValue={performer.name}
                        ref={(el) => (inputRefs.current[index] = el)}
                      />
                      <button
                        type="submit"
                        className="btn btn-dark performer-update-button"
                      >
                        Update
                      </button>
                    </div>
                  </form>
                </div> */}

                  <div
                    className="table-data"
                    style={{ cursor: "pointer" }}
                    onClick={() => deletePerformer(performer?.id)}
                  >
                    <i className="fa fa-trash"></i>
                  </div>
                </Link>
              ))}
          </div>
        </>
      </div>
    </div>
  );
};

export default PerfomerList;
