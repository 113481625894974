/** @format */

import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTimeStampsAsyncThunk } from "../redux/pagesSlices/timeStampSlice";
import { getContentsAsyncThunk } from "../redux/pagesSlices/contentSlice";
import { FaAngleDown } from "react-icons/fa";
import { Accordion } from "react-bootstrap";
import {
  createPerformerAsyncThunk,
  getPerformersAsyncThunk,
} from "../redux/pagesSlices/performerSlice";

const GplayerListing = () => {
  const dispatch = useDispatch();

  const [selectedVideoId, setSelectedVideoId] = useState();

  const dropdownRef = useRef();

  const contents = useSelector((state) => state?.contents?.contents);
  console.log(contents);

  const timeStamps = useSelector(
    (state) => state?.timeStamp?.timeStamps?.results
  );

  const selectedChapters = timeStamps?.filter(
    (chapter) => chapter?.contentId?.id === selectedVideoId
  );
  console.log(selectedChapters);

  console.log(timeStamps);

  const handleVideoSelect = (id) => {
    if (id !== selectedVideoId) setSelectedVideoId(id);
  };

  const closeChaptersList = () => {
    setSelectedVideoId(null);
  };

  useEffect(() => {
    dispatch(
      getTimeStampsAsyncThunk({
        sortBy: "name:ase",
        populate: "contentId",
        limit: 100,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setSelectedVideoId(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    dispatch(
      getContentsAsyncThunk({
        sortBy: "updatedAt:desc",
        mimeType: "video",
        limit: 100,
      })
    );
  }, [dispatch]);

  const handleBackup = (timeStamps) => {
    if (!timeStamps?.length) {
      console.log("inside return, timestamps are:", timeStamps);
      return;
    }

    console.log("The timestamps result is:", timeStamps);

    // Add 'shareableUrl' to headers
    console.log(timeStamps);
    const headers = [...Object.keys(timeStamps[0]), "shareAbleUrl"];

    console.log("Headers are:", headers);

    const escapeCSVValue = (value) => {
      if (typeof value === "string") {
        return `"${value.replace(/"/g, '""')}"`;
      }
      if (
        Array.isArray(value) ||
        (typeof value === "object" && value !== null)
      ) {
        return `"${JSON.stringify(value).replace(/"/g, '""')}"`;
      }
      return value;
    };

    const flattenObject = (obj) => {
      // Include 'shareableUrl' in the flattened object
      // console.log("The currentTime is:", timeStringToSeconds(obj.startTime))
      const flattened = headers
        .map((header) => {
          if (header === "shareAbleUrl") {
            return escapeCSVValue(
              generateShareableUrl(timeStringToSeconds(obj.startTime))
            );
          }
          return escapeCSVValue(obj[header]);
        })
        .join(",");
      console.log(flattened);
      return flattened;
    };

    const csv = [headers.join(","), ...timeStamps.map(flattenObject)].join(
      "\n"
    );

    console.log("csv is:", csv);

    const blob = new Blob([csv], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `${timeStamps[0]?.contentId?.name}_${formattedTime}_timestamps.csv`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const generateShareableUrl = (currentTime) => {
    const baseUrl = `${window.location.origin}/#/player/${selectedVideoId}`;
    const currentChapter = findCurrentChapter(currentTime, selectedChapters);
    let timeString;
    if (currentChapter) {
      timeString = currentTime?.toFixed(0)?.padStart(6, "0");
      const formattedChapter = formatChapter(currentChapter);
      return `${baseUrl}?t=${timeString}&${formattedChapter}`;
    }
    return `${baseUrl}`;
  };

  const findCurrentChapter = (currentTime, timeStamps) => {
    // console.log("TimeStamps are: ", timeStamps)
    const currentChapter =
      timeStamps &&
      timeStamps?.find((chapter) => {
        const startTimeInSeconds = timeStringToSeconds(chapter.startTime);
        const endTimeInSeconds = timeStringToSeconds(chapter.endTime);
        return (
          currentTime >= startTimeInSeconds && currentTime <= endTimeInSeconds
        );
      });
    return currentChapter;
  };

  const formatChapter = (chapter) => {
    const { name, startTime, endTime } = chapter;
    const formattedName = name.replace(/\s+/g, "_");
    const formattedStartTime = startTime.replace(/:/g, "_");
    const formattedEndTime = endTime.replace(/:/g, "_");
    return `${selectedChapters[0]?.contentId?.name}_${formattedName}_S_${formattedStartTime}_E_${formattedEndTime}`;
  };

  function timeStringToSeconds(timeString) {
    var timeParts = timeString?.split(":");
    var hours = parseInt(timeParts?.[0]);
    var minutes = parseInt(timeParts?.[1]);
    var seconds = hours * 3600 + minutes * 60 + parseInt(timeParts?.[2]);
    return seconds;
  }

  const currentTime = new Date();

  const formattedTime = currentTime
    .toLocaleString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
    .replace(", ", "_")
    .replace(/\//g, "-");

  console.log("formattedTime>>>>>>>>>>>", formattedTime);

  console.log("🚀 ~ GplayerListing ~ timeStamps:", timeStamps);

  return (
    <div>
      <div className="Report-Tabs" style={{ flexGrow: "1" }}>
        <h2 className="">Reports</h2>
        <div className="f-container">
          <div className="collection-outer-body">
            <div className="assets-table-head">
              <div className="row">
                <div className="col-lg-8 col-8">
                  <div className="table-row">
                    <div className="table-data">
                      <p>Content Name</p>
                    </div>
                    <div className="table-data">Views Count</div>
                    {/* <div className="table-data">
                      <p>Chapter Name</p>
                    </div> */}
                  </div>
                </div>
                <div className="col-lg-4 col-4">
                  <div className="table-row chapter-heading-container">
                    {/* <div className="table-data">
                      <p>StartTime</p>
                    </div>
                    <div className="table-data">
                      <p>EndTime</p>
                    </div>
                    <div className="table-data">
                      <p>Status</p>
                    </div> */}

                    <div className="table-data table-data-chapters-heading">
                      Chapters
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* {timeStamps &&
              timeStamps.map((item, i) => (
                <div key={i}>
                  <div className="assets-table-body">
                    <div className="row">
                      <div className="col-lg-4 col-4">
                        <div className="table-row">
                          <div className="table-data">
                            <h3>
                              {item?.contentId?._id ||
                                item?.contentId?.name ||
                                "No content"}
                            </h3>
                          </div>

                          <div className="table-data">
                            <h3>{item?.name}</h3>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8 col-8">
                        <div className="table-row">
                          <div className="table-data">{item?.startTime}</div>
                          <div className="table-data">
                            <p>{item?.endTime}</p>
                          </div>
                          <div className="table-data">{item?.status}</div>
                          <div className="table-data">
                            {item?.contentId?.viewsCount || 0}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
            </div>
          );
        }; */}
            <Accordion defaultActiveKey="0">
              {contents &&
                contents?.results?.map((content, i) => (
                  <Accordion.Item
                    eventKey={i}
                    onClick={() => handleVideoSelect(content.id)}
                  >
                    {/* <div key={i}> */}

                    <Accordion.Header>
                      <div className="assets-table-body accordion-stmt">
                        <div className="row">
                          <div className="col-lg-8 col-8">
                            <div className="table-row accordion-row">
                              <div className="table-data">
                                <h3>
                                  {/* {content?.name || "(Name Unavailable)"} */}
                                  {content?.name?.slice(0, 25) +
                                    (content?.name?.length > 25 ? "..." : "") ||
                                    "N/A"}
                                </h3>
                              </div>
                              <div className="table-data">
                                {content?.viewsCount || 0}
                              </div>
                            </div>
                          </div>
                          {/* <div className="col-lg-8 col-8">
                        <div className="table-row"> */}

                          {/* <div className="table-data">
                            {content?.viewsCount || 0}
                          </div> */}

                          {/* <div className="table-data chapters-list-container" onClick={() => handleVideoSelect(content.id)}>
                            <FaAngleDown /> */}
                          {/* {selectedVideoId && selectedVideoId === content.id && (<> { selectedChapters.length > 0 ? (
                              
                              <div ref={dropdownRef} className="assets-table-body chapters-list">
                                <div className="table-row dropdown-row">
                                  <div className="table-data">
                                    <b>CH Name</b>
                                  </div>

                                  <div className="table-data">
                                    <b>Start Time</b>
                                  </div>

                                  <div className="table-data">
                                    <b>End Time</b>
                                  </div>
                                </div>

                                {selectedChapters.map((chapter) => (
                                  <div key={chapter.id} className="table-row dropdown-row">
                                    <div className="table-data">
                                      {chapter.name}
                                    </div>

                                    <div className="table-data">
                                      {chapter.startTime}
                                    </div>

                                    <div className="table-data">
                                      {chapter.endTime}
                                    </div>
                                  </div>
                                ))}
                              </div>
                              
                            ) : (
                              <div ref={dropdownRef} className="no-chapters-found">
                                <p>No Chapters Found</p>
                              </div>
                            )}</>)} */}
                        </div>
                        {/* </div>
                      </div> */}
                        {/* </div> */}
                      </div>
                    </Accordion.Header>

                    <Accordion.Body>
                      {selectedVideoId && selectedVideoId === content.id && (
                        <>
                          {selectedChapters.length > 0 ? (
                            <>
                              <div className="assets-table-body">
                                <div className="table-row dropdown-row">
                                  <div className="table-data">
                                    <b>CH Name</b>
                                  </div>
                                  <div className="table-data">
                                    <b>Chapter Reactions ZipCode</b>
                                  </div>
                                  <div className="table-data">
                                    <b>Chapter Reactions Users</b>
                                  </div>

                                  <div className="table-data">
                                    <b>Chapter Reactions Ip's</b>
                                  </div>

                                  <div className="table-data">
                                    <b>Chapter Performer's</b>
                                  </div>

                                  <div className="table-data">
                                    <b>Start Time</b>
                                  </div>
                                  <div className="table-data">
                                    <b>End Time</b>
                                  </div>
                                  <div className="table-data">
                                    <b>View Count</b>
                                  </div>
                                </div>

                                {selectedChapters.map((chapter) => {
                                  console.log("🚀 ~ chapter:", chapter);
                                  return (
                                    <div
                                      key={chapter.id}
                                      className="table-row dropdown-row"
                                    >
                                      <div className="table-data">
                                        {chapter.name}
                                      </div>
                                      <div className="table-data">
                                        <select
                                          name="reactions"
                                          id="reactions"
                                          className="reports-reactions-dropdown"
                                        >
                                          <option default hidden disabled>
                                            <FaAngleDown />
                                          </option>
                                          {chapter?.reactions?.length > 0 ? (
                                            chapter.reactions.map(
                                              (reaction) =>
                                                reaction?.zipcode && (
                                                  <option
                                                    key={reaction.zipcode}
                                                    disabled
                                                  >
                                                    {reaction.zipcode}
                                                  </option>
                                                )
                                            )
                                          ) : (
                                            <option disabled>
                                              No Zip Code
                                            </option>
                                          )}
                                        </select>
                                      </div>

                                      <div className="table-data">
                                        <select
                                          name="reactions"
                                          id="reactions"
                                          className="reports-reactions-dropdown"
                                        >
                                          <option default hidden disabled>
                                            <FaAngleDown />
                                          </option>
                                          {chapter?.reactions?.length > 0 ? (
                                            chapter.reactions.map(
                                              (reaction) =>
                                                reaction?.user && (
                                                  <option
                                                    key={reaction.user}
                                                    disabled
                                                  >
                                                    {reaction.user}
                                                  </option>
                                                )
                                            )
                                          ) : (
                                            <option disabled>
                                              No Reaction user
                                            </option>
                                          )}
                                        </select>
                                      </div>

                                      <div className="table-data">
                                        <select
                                          name="reactions"
                                          id="reactions"
                                          className="reports-reactions-dropdown"
                                        >
                                          <option default hidden disabled>
                                            <FaAngleDown />
                                          </option>
                                          {chapter?.reactions?.length > 0 ? (
                                            chapter.reactions.map(
                                              (reaction) =>
                                                reaction?.ip && (
                                                  <option
                                                    key={reaction.ip}
                                                    disabled
                                                  >
                                                    {reaction.ip}
                                                  </option>
                                                )
                                            )
                                          ) : (
                                            <option disabled>No Ip's</option>
                                          )}
                                        </select>
                                      </div>

                                      <div className="table-data">
                                        <select
                                          name="reactions"
                                          id="reactions"
                                          className="reports-reactions-dropdown"
                                        >
                                          <option default hidden disabled>
                                            <FaAngleDown />
                                          </option>
                                          {chapter?.performer?.length > 0 ? (
                                            chapter.performer.map(
                                              (item) =>
                                                item && (
                                                  <option key={item} disabled>
                                                    {item}
                                                  </option>
                                                )
                                            )
                                          ) : (
                                            <option disabled>
                                              No Performer's
                                            </option>
                                          )}
                                        </select>
                                      </div>

                                      <div className="table-data">
                                        {chapter.startTime}
                                      </div>
                                      <div className="table-data">
                                        {chapter.endTime}
                                      </div>
                                      <div className="table-data">
                                        {chapter.viewsCount}
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                              <div className="reports-export-button-container">
                                <button
                                  className="btn btn-dark reports-export-button"
                                  onClick={() => handleBackup(selectedChapters)}
                                >
                                  Backup/Export
                                </button>
                              </div>
                            </>
                          ) : (
                            <div className="no-chapters-found">
                              <p>No Chapters Found</p>
                            </div>
                          )}
                        </>
                      )}
                    </Accordion.Body>
                    {/* </div> */}
                  </Accordion.Item>
                ))}
            </Accordion>
          </div>
        </div>
      </div>
      {/* <button onClick={createPerformer}>Add Performer</button>
      {
        performers && performers.map(performer => <h1>{performer.name}</h1>)
      } */}
    </div>
  );
};

export default GplayerListing;
