import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import dashjs from "dashjs";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import {
  createTimeStampAsyncThunk,
  deleteAllTimeStampWithLinkAsyncThunk,
  deleteYouTimeStampAsyncThunk,
  getTimeStampsAsyncThunk,
  importTimeStampAsyncThunk,
  updateTimeStampAsyncThunk,
} from "../redux/pagesSlices/timeStampSlice";
import { handleModel } from "../redux/layoutSlices/modelSlice";
import { parseUrlFromModel } from "../helpers/asset";
import { useParams } from "react-router-dom";
import { getLinkAsyncThunk } from "../redux/pagesSlices/linkSlice";
import ReactPlayer from "react-player";

const YoutubeCreateStamps = () => {
  const [timeRanges, setTimeRanges] = useState([
    { startTime: "", endTime: "", name: "", status: "", description: "" },
  ]);
  console.log("🚀 ~ YoutubeCreateStamps ~ timeRanges:", timeRanges);
  const [currentIndex, setCurrentIndex] = useState(null);

  const [selectedTime, setSelectedTime] = useState(null);
  const videoRef = useRef(null);
  const params = useParams();
  const dispatch = useDispatch();
  const modelArgs = useSelector(
    (state) => state.model?.modelArgs?.RightSidebarBase
  );
  const oldData = modelArgs?.args;
  const link = useSelector((state) => state.links.link);
  console.log("🚀 ~ YoutubeCreateStamps ~ link:", link);
  const timeStamps = useSelector((state) => state.timeStamp.timeStamps);

  useEffect(() => {
    let queryParams = {};

    dispatch(getLinkAsyncThunk({ id: params?.id, queryParams }));
    dispatch(
      getTimeStampsAsyncThunk({
        linkId: params?.id,
        sortBy: "name:ase",
        limit: 20,
      })
    );
  }, [dispatch, params?.id]);

  useEffect(() => {
    if (timeStamps && timeStamps.results) {
      setTimeRanges(
        timeStamps.results.map((timeStamp) => ({
          name: timeStamp?.name,
          description: timeStamp?.description,
          status: timeStamp?.status,
          startTime: timeStamp?.startTime,
          endTime: timeStamp?.endTime,
          id: timeStamp?.id,
        }))
      );
    }
  }, [timeStamps]);

  const handleChangeTimeRange = (index, field, value) => {
    const formatTimeWithColon = (time) =>
      time
        .replace(/[^0-9]/g, "")
        .slice(0, 6)
        .replace(/(\d{2})(?=\d)/g, "$1:");

    const newTimeRanges = [...timeRanges];
    if (field === "startTime" || field === "endTime") {
      value = formatTimeWithColon(value);
    }
    newTimeRanges[index][field] = value;
    setTimeRanges(newTimeRanges);
  };

  const handleKeyPress = (event, index, field) => {
    const increment = 1; // You can change this increment as desired
    let newValue = timeRanges[index][field];
    if (event.key === "ArrowUp") {
      newValue = increaseTime(newValue, increment);
    } else if (event.key === "ArrowDown") {
      newValue = decreaseTime(newValue, increment);
    }
    handleChangeTimeRange(index, field, newValue);
  };

  const increaseTime = (timeString, increment) => {
    const timeArray = timeString.split(":");
    let [hours, minutes, seconds] = timeArray.map(Number);

    seconds += increment;
    if (seconds >= 60) {
      seconds = 0;
      minutes++;
    }
    if (minutes >= 60) {
      minutes = 0;
      hours++;
    }
    hours %= 24;

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  const decreaseTime = (timeString, increment) => {
    const timeArray = timeString.split(":");
    let [hours, minutes, seconds] = timeArray.map(Number);

    seconds -= increment;
    if (seconds < 0) {
      seconds = 59;
      minutes--;
    }
    if (minutes < 0) {
      minutes = 59;
      hours--;
    }
    if (hours < 0) {
      hours = 23;
    }

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time - hours * 3600) / 60);
    const seconds = Math.floor(time - hours * 3600 - minutes * 60);

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  // const handleProgressBarClick = (e) => {
  //   const progressBar = e.target;
  //   const rect = progressBar.getBoundingClientRect();
  //   const offsetX = e.clientX - rect.left;
  //   const width = rect.width;
  //   const clickTime = (offsetX / width) * videoRef.current.duration;

  //   const newTimeRange = {
  //     startTime:
  //       timeRanges.length === 0
  //         ? "00:00:00"
  //         : timeRanges[timeRanges.length - 1].endTime,
  //     endTime: formatTime(clickTime),
  //     name: `CH ${timeRanges.length + 1}`,
  //     status: "publish",
  //   };

  //   setTimeRanges([...timeRanges, newTimeRange]);
  //   setSelectedTime(clickTime);
  // };

  // const handleOverlayClick = (e) => {
  //   const overlay = e.currentTarget;
  //   const rect = overlay.getBoundingClientRect();
  //   const offsetX = e.clientX - rect.left;
  //   const width = rect.width;
  //   const clickTime = (offsetX / width) * videoRef.current.duration;

  //   const newTimeRange = {
  //     startTime: formatTime(clickTime),
  //     endTime: "",
  //     name: `CH ${timeRanges.length + 1}`,
  //     status: "publish",
  //   };

  //   setTimeRanges([...timeRanges, newTimeRange]);
  // };

  const handleAddTimeRange = () => {
    setTimeRanges([
      ...timeRanges,
      { startTime: "", endTime: "", name: "", status: "", description: "" },
    ]);
  };

  // const handleAddTimeRangeInNextIndex = (index) => {
  //   if (index !== null && index !== undefined) {
  //     const newTimeRanges = [...timeRanges];
  //     newTimeRanges.splice(index + 1, 0, {
  //       startTime: "",
  //       endTime: "",
  //       name: "",
  //       description: "",
  //       status: "",
  //     });
  //     setTimeRanges(newTimeRanges);
  //   }
  // };

  const handleAddTimeRangeInNextIndex = (index) => {
    if (
      index !== null &&
      index !== undefined &&
      index < timeRanges.length - 1
    ) {
      const newTimeRanges = [...timeRanges];

      // Calculate the new start and end times based on adjacent indices
      const upperIndexEndTime = newTimeRanges[index].endTime;
      const newStartTime = addSeconds(upperIndexEndTime, 1); // Start time of the new timestamp
      const newEndTime = addSeconds(newStartTime, 1); // End time of the new timestamp

      // Increment start time of the timestamp below by four seconds
      const lowerIndex = index + 1;
      if (lowerIndex < newTimeRanges.length) {
        newTimeRanges[lowerIndex].startTime = addSeconds(
          newTimeRanges[lowerIndex].startTime,
          2
        );
      }

      // Insert the new time stamp with the calculated times
      newTimeRanges.splice(index + 1, 0, {
        startTime: newStartTime,
        endTime: newEndTime,
        name: `CH${(extractNumber(newTimeRanges[index].name) + 1)
          .toString()
          .padStart(3, "0")}`,
        description: "",
        status: "publish",
      });

      setTimeRanges(newTimeRanges);
    }
  };

  // Function to extract number from the time stamp name
  const extractNumber = (name) => {
    const matches = name.match(/\d+/);
    return matches ? parseInt(matches[0]) : 0;
  };

  // Function to add seconds to a time in "HH:mm:ss" format
  const addSeconds = (time, seconds) => {
    const [hours, minutes, oldSeconds] = time.split(":").map(Number);
    const newSeconds = oldSeconds + seconds;
    const newMinutes = minutes + Math.floor(newSeconds / 60);
    const newHours = hours + Math.floor(newMinutes / 60);
    return `${padZero(newHours)}:${padZero(newMinutes % 60)}:${padZero(
      newSeconds % 60
    )}`;
  };

  // Function to pad zero to single digit numbers
  const padZero = (num) => {
    return num.toString().padStart(2, "0");
  };

  const deleteTimeRange = (id) => {
    dispatch(
      deleteYouTimeStampAsyncThunk({
        id,
        linkId: params?.id,
        callBack: () => {
          // Callback logic if needed
        },
      })
    );
  };

  const deleteTimeRangeNewly = (index) => {
    // Filter out the time range at the specified index
    const updatedTimeRanges = timeRanges.filter((_, i) => i !== index);
    setTimeRanges(updatedTimeRanges);
  };

  console.log(
    "🚀 ~ YoutubeCreateStamps ~ videoRef.current?.duration:",
    videoRef.current?.getDuration()
  );

  const autoGenerateChapters = async () => {
    console.log("🚀 ~ autoGenerateChapters ~ timeRanges:", timeRanges);
    if (timeRanges.length === 0) {
      console.log("cllllllllllll:");
      const totalDuration = await videoRef.current?.getDuration();
      console.log("🚀 ~ autoGenerateChapters ~ totalDuration:", totalDuration);
      if (!isNaN(totalDuration) && totalDuration !== 0) {
        const segmentDuration = totalDuration / 12;
        const chapters = [];
        let startTime = "00:00:00";
        let endTime = "";
        let chapterNum = 1;
        const step = Math.floor(Math.random() * 10) + 1; // Random step between 1 and 10

        for (let i = 0; i < 12; i++) {
          endTime = formatTime(segmentDuration * (i + 1));
          const name = `CH${chapterNum.toString().padStart(3, "0")}`;
          const description = "";
          const status = "publish";
          chapters.push({ startTime, endTime, name, status, description });
          startTime = addSeconds(endTime, 1); // Increment start time by the step
          chapterNum += step;
        }

        setTimeRanges(chapters);
      }
    }
  };

  useEffect(() => {
    const timeout = setTimeout(autoGenerateChapters, 6000);
    return () => clearTimeout(timeout);
  }, [timeRanges.length, autoGenerateChapters]);

  const createCollectionHandler = async (data, { resetForm }) => {
    const payload = timeRanges.map(
      ({ startTime, endTime, name, status, description }) => ({
        startTime,
        endTime,
        linkId: params?.id,
        name,
        description,
        status: status ? status : "publish",
      })
    );

    try {
      if (timeStamps?.results?.length > 0) {
        const promises = payload.map((timeStamp, index) => {
          if (timeStamps?.results[index]?.id) {
            return dispatch(
              updateTimeStampAsyncThunk({
                id: timeStamps?.results[index]?.id,
                data: timeStamp,
              })
            );
          } else {
            return dispatch(
              createTimeStampAsyncThunk({
                data: timeStamp,
              })
            );
          }
        });

        await Promise.all(promises);
      } else {
        await dispatch(
          createTimeStampAsyncThunk({
            data: payload,
          })
        );
      }

      // All timestamps updated successfully
      toast.success("All TimeStamps Updated Successfully!", {
        autoClose: false,
      });

      closeModel();
      setTimeRanges([
        { startTime: "", endTime: "", name: "", status: "", description: "" },
      ]);
      resetForm();
    } catch (error) {
      console.error("Error updating timestamps:", error);
      // Handle error here
    }
  };

  const closeModel = () => {
    dispatch(handleModel({ model: "RightSidebarBase", state: false }));
  };

  const handleDeleteAll = () => {
    dispatch(deleteAllTimeStampWithLinkAsyncThunk({ linkId: params?.id }));
  };

  const handleBackup = () => {
    // Create CSV headers from the keys of the first object in timeStamps array
    const headers = Object.keys(timeStamps?.results[0]);

    // Create CSV data by combining headers and values of each object
    const csv = [
      headers.join(","), // Join headers with comma
      ...timeStamps?.results.map((item) =>
        headers.map((header) => item[header]).join(",")
      ), // Map each object to CSV row
    ].join("\n"); // Join rows with newline

    // Create a Blob object from the CSV data
    const blob = new Blob([csv], { type: "text/csv" });

    // Create a temporary URL for the Blob object
    const url = URL.createObjectURL(blob);

    // Create a temporary link element
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "timestamps.csv");

    // Simulate a click on the link to trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const [file, setFile] = useState(null);
  console.log("🚀 ~ CreateStamps ~ file:", file);
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFile(file, () => {
      console.log("File set:", file);
    });
  };

  const handleImport = () => {
    console.log("File to import:", file);
    if (!file) {
      console.log("No file selected.");
      return;
    }
    dispatch(importTimeStampAsyncThunk({ file: file }));
  };

  return (
    <div className="container">
      <h2 className="my-4">Custom Video Playback with Chapter Control</h2>
      <h4 className="mt-4 mb-4">Name : "{link?.title ?? link?.name}"</h4>

      <Formik
        initialValues={{
          ...(oldData?.id
            ? {
              ...oldData,
              video: oldData?.video?.id,
              id: undefined,
            }
            : {
              name: "",
              description: "",
              startTime: "",
              endTime: "",
              video: "",
              status: "",
            }),
        }}
        onSubmit={createCollectionHandler}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          values,
          errors,
          touched,
        }) => (
          <div className="create-course-sibebar">
            {link?.name == "youtube" ? (
              <ReactPlayer
                controls
                style={{ minHeight: "70vh", margin: "auto" }}
                width="100%"
                height="100%"
                // src={parseUrlFromModel(content)}
                url={link?.link}
                ref={videoRef}
                volume={0.1}
              />
            ) : (
              <div className="text-center">
                <h3 style={{ color: "red" }}>
                  Google Drive Content Not Supported{" "}
                </h3>
                <p style={{ color: "red" }}>
                  Please Create Time Stamps Manually{" "}
                </p>
              </div>
            )}

            {/* <div className="progress" onClick={handleProgressBarClick}>
              <div
                className="progress-bar"
                role="progressbar"
                style={{ width: "0%" }}
                aria-valuenow="0"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div> */}

            {/* Buttons */}
            <div className="d-flex alignitems-center flex-wrap gap-3 mt-3">
              <button
                onClick={handleAddTimeRange}
                className="btn btn-secondary btn-block"
              >
                +
              </button>
              <button className="btn btn-dark btn-block delete-btn" onClick={handleDeleteAll}>
                Delete All Meta Data
              </button>

              <label htmlFor="fileInput" className="btn btn-secondary  File-input">
                <input type="file" onChange={handleFileChange} />
                Upload a File
              </label>

              <button className="btn delete-btn btn-dark" onClick={handleImport}>
                Import
              </button>

              <button className="delete-btn btn btn-secondary" onClick={handleBackup}>
                Backup/Export
              </button>
            </div>

            {timeRanges?.map((timeRange, index) => (
              <div className="Create-box row mb-3 mt-3" key={index}>
                <div className="col-lg-3">
                  <label>
                    <h5>CH Name</h5>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Your chapter Name"
                    value={timeRange?.name}
                    onChange={(e) =>
                      handleChangeTimeRange(index, "name", e.target.value)
                    }
                  />
                </div>

                <div className="col-lg-3">
                  <label>
                    <h5>Status</h5>
                  </label>

                  <div className="checkbox-flex-box">
                    <label htmlFor={`publish_${index}`} className="mr-2">
                      Publish
                      <input
                        type="checkbox"
                        id={`publish_${index}`}
                        checked={timeRange.status === "publish"}
                        onChange={(e) =>
                          handleChangeTimeRange(
                            index,
                            "status",
                            e.target.checked ? "publish" : "hide"
                          )
                        }
                      />
                    </label>
                    <label htmlFor={`hide_${index}`} className="mr-2">
                      Hide/Play
                      <input
                        type="checkbox"
                        id={`hide_${index}`}
                        checked={timeRange.status === "hide"}
                        onChange={(e) =>
                          handleChangeTimeRange(
                            index,
                            "status",
                            e.target.checked ? "hide" : "publish"
                          )
                        }
                      />
                    </label>
                    <label htmlFor={`forcePlay_${index}`}>
                      Force Hide
                      <input
                        type="checkbox"
                        id={`forcePlay_${index}`}
                        checked={timeRange.status === "force play"}
                        onChange={(e) =>
                          handleChangeTimeRange(
                            index,
                            "status",
                            e.target.checked ? "force play" : "publish"
                          )
                        }
                      />
                    </label>
                  </div>
                </div>

                {/* Time range fields */}
                <div className="col-lg-2">
                  <label>
                    <h5>Start Time</h5>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    pattern="[0-9]{2}:[0-9]{2}:[0-9]{2}"
                    placeholder="hh:mm:ss"
                    value={timeRange.startTime}
                    onChange={(e) =>
                      handleChangeTimeRange(index, "startTime", e.target.value)
                    }
                    onKeyDown={(e) => handleKeyPress(e, index, "startTime")}
                  />
                </div>
                <div className="col-lg-2">
                  <label>
                    <h5>End Time</h5>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    pattern="[0-9]{2}:[0-9]{2}:[0-9]{2}"
                    placeholder="hh:mm:ss"
                    value={timeRange.endTime}
                    onChange={(e) =>
                      handleChangeTimeRange(index, "endTime", e.target.value)
                    }
                    onKeyDown={(e) => handleKeyPress(e, index, "endTime")}
                  />
                </div>

                {timeRange?.id ? (
                  <div
                    className="col-md-1 m-auto"
                    style={{ cursor: "pointer" }}
                    onClick={() => deleteTimeRange(timeRange?.id)}
                  >
                    <i className="fa fa-trash"></i>
                  </div>
                ) : (
                  <div
                    className="col-md-1 m-auto"
                    style={{ cursor: "pointer" }}
                    onClick={() => deleteTimeRangeNewly(index)} // Pass the index to deleteTimeRange
                  >
                    {/* new entry delete */}
                    <i className="fa fa-trash"></i>
                  </div>
                )}

                <div>
                  <label className="mt-4">
                    <h5>CH Description</h5>
                  </label>
                  <textarea
                    type="text"
                    cols={30}
                    rows={5}
                    className="form-control"
                    placeholder="Enter description here"
                    value={timeRange?.description}
                    onChange={(e) =>
                      handleChangeTimeRange(
                        index,
                        "description",
                        e.target.value
                      )
                    }
                  />
                </div>

                <button
                  onClick={() => handleAddTimeRangeInNextIndex(index)}
                  className="btn btn-secondary btn-block mt-3 me-3"
                >
                  +
                </button>
              </div>
            ))}

            {/* Button to add new time range */}
            <div className="row mb-3 btn-box">
              <div className="col-md-12">
                <div className="d-flex align-items-center gap-3 flex-wrap mt-1">
                  {/* <button
                    onClick={handleAddTimeRange}
                    className="btn btn-secondary btn-block"
                  >
                    +
                  </button> */}

                  {timeRanges.length == 0 && (
                    <button
                      type="submit"
                      onClick={handleSubmit}
                      className="btn btn-dark btn-block"
                    >
                      Create
                    </button>
                  )}

                  {timeRanges.length > 0 && (
                    <button
                      type="submit"
                      onClick={handleSubmit}
                      className="btn btn-dark btn-block"
                    >
                      Update
                    </button>
                  )}
                  {/* <button className="delete-btn" onClick={handleDeleteAll}>Delete All Meta Data</button> */}
                </div>
              </div>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default YoutubeCreateStamps;
