import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import {
  parseUrlFromModel,
} from "../helpers/asset.js";
import { useDispatch, useSelector } from "react-redux";
import {
  getTimeStampsAsyncThunk,
  updateTimeStampAsyncThunk,
} from "../redux/pagesSlices/timeStampSlice.js";
import annyang from "annyang"
import {
  getContentAsyncThunk,
  getContentsAsyncThunk,
} from "../redux/pagesSlices/contentSlice.js";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";
import { Button } from "react-bootstrap";
import { colors } from "@primer/primitives";

// SVGs
import { FaMicrophone } from "react-icons/fa";
import { FaMicrophoneSlash } from "react-icons/fa";
import WordsAroundMic from '../assets/images/Words-Around-Mic.jsx'
import { FaEye } from "react-icons/fa";

// REACTION
import ChapterListCard from "../components/ChapterListCard.js";
import ChapterReactCard from "../components/ChapterReactCard.js";
import axios from "axios";
import { Clippy, Check } from "../helpers/copy_icons.js";
import TimeStampUpdateModel from "../components/modals/timeStamps/TimeStampUpdateModel.js";
import { colorslist } from "../constants.js";


const HomePlayer = () => {
  const [isListening, setIsListening] = useState(false);

  const location = useLocation();
  const [isMuted , setIsMuted] = useState(false)
  const [ isPlay , setIsPlay ] = useState(true)
  let params = useParams();
  const [currentTime, setCurrentTime] = useState(0);

  const generateShareableUrl = (currentTime) => {
    console.log(currentTime)
    const baseUrl = `${window.location.origin}/#/player/${params.id}`;
    const currentChapter = findCurrentChapter(currentTime, timeStamps);
    let timeString;
    timeString = Math.floor(currentTime)?.toFixed(0)?.padStart(6, "0");
    if (currentChapter) {
      console.log("TimeString to share URL",timeString)
      const formattedChapter = formatChapter(currentChapter);
      return `${baseUrl}?t=${timeString}&${formattedChapter}`;
    }
    return `${baseUrl}?t=${timeString}`;
  };

  const findCurrentChapter = (currentTime, timeStamps) => {
    const currentChapter =
      timeStamps &&
      timeStamps?.find((chapter) => {
        const startTimeInSeconds = timeStringToSeconds(chapter.startTime);
        const endTimeInSeconds = timeStringToSeconds(chapter.endTime);
        return (
          currentTime >= startTimeInSeconds && currentTime <= endTimeInSeconds
        );
      });
    return currentChapter;
  };

  const formatChapter = (chapter) => {
    const { name, startTime, endTime } = chapter;
    const formattedName = name.replace(/\s+/g, "_");
    const formattedStartTime = startTime.replace(/:/g, "_");
    const formattedEndTime = endTime.replace(/:/g, "_");
    return `${content?.name}_${formattedName}_S_${formattedStartTime}_E_${formattedEndTime}`;
  };

  function timeStringToSeconds(timeString) {
    var timeParts = timeString?.split(":");
    var hours = parseInt(timeParts?.[0]);
    var minutes = parseInt(timeParts?.[1]);
    var seconds = hours * 3600 + minutes * 60 + parseInt(timeParts?.[2]);
    return seconds;
  }

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("Link copied to clipboard!", { autoClose: false });
      })
      .catch((error) => {
        toast.error("Error copying link to clipboard!");
      });
  };

  // const handlePlayerReady = () => {
  //   const urlParams = new URLSearchParams(location.search);
  //   const startTime = urlParams.get("t");
  //   if (startTime) {
  //     const startTimeSeconds = parseInt(startTime, 10) || 0;
  //     playerRef.current?.seekTo(startTimeSeconds, "seconds");
  //   }
  // };

  const handlePlayerReady = () => {
    const urlParams = new URLSearchParams(location.search);
    const startTime = urlParams.get("t");
    const startTimeSeconds = parseInt(startTime, 10) || 0;
    console.warn(startTime , "start time here")


    const hash = window.location.hash;
    const startTimeMatch = hash.match(/S_(\d{2})_(\d{2})_(\d{2})/);
    const endTimeMatch = hash.match(/E_(\d{2})_(\d{2})_(\d{2})/);

    if (startTimeMatch && endTimeMatch) {
      const startTimeArray = startTimeMatch
        .slice(1)
        .map((val) => parseInt(val, 10));
      // const endTimeArray = endTimeMatch
      //   .slice(1)
      //   .map((val) => parseInt(val, 10));

      const startSeconds =
        startTimeArray[0] * 3600 + startTimeArray[1] * 60 + startTimeArray[2];
      // const endSeconds =
      //   endTimeArray[0] * 3600 + endTimeArray[1] * 60 + endTimeArray[2];
      if (startTime) {
        playerRef.current.seekTo(startTime, "seconds");
        setIsMuted(true)
        setIsPlay(true)
      } else {
        playerRef.current.seekTo(startSeconds, "seconds");
        setIsPlay(true)
        setIsMuted(true)
      }
    }
    if (startTime) {
      setIsMuted(true)
      playerRef.current.seekTo(startTime, "seconds");
      setIsPlay(true)
    }
  };

  const handleProgress = ({ playedSeconds }) => {
    const hash = window.location.hash;
    const endTimeMatch = hash.match(/E_(\d{2})_(\d{2})_(\d{2})/);

    if (endTimeMatch) {
      const endTimeArray = endTimeMatch
        .slice(1)
        .map((val) => parseInt(val, 10));
      const endSeconds =
        endTimeArray[0] * 3600 + endTimeArray[1] * 60 + endTimeArray[2];

      if (playedSeconds >= endSeconds) {
        playerRef.current.getInternalPlayer().pause();
      }
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      const time = playerRef?.current?.getCurrentTime();
      if (time) {
        setCurrentTime(time);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const playerRef = React.useRef();

  const d = useDispatch();

  const [ip, setIp] = useState("");
  const fetchData = async () => {
    try {
      const ipResponse = await axios.get("https://api.ipify.org?format=json");
      const ipData = ipResponse.data;
      setIp(ipData?.ip);
      let queryParams = {};
      if (user?.id) {
        queryParams.userId = user?.id;
      } else {
        queryParams.ipAddress = ip || ipData?.ip || "unknown";
      }
      await d(getContentAsyncThunk({ id: params?.id, queryParams }));
    } catch (error) {
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    d(
      getTimeStampsAsyncThunk({
        contentId: params?.id,
        populate: "contentId",
        sortBy: "name:asc",
        limit: 60,
      })
    );
    fetchData();
  }, [d, params?.id]);

  const timeStamps = useSelector((s) => s?.timeStamp?.timeStamps?.results);
  // console.log("🚀 ~ HomePlayer ~ timeStamps:", timeStamps)
  const [updateTimeStamps, setUpdateTimeStamps] = useState(timeStamps);

  let user = JSON.parse(localStorage.getItem("user"));

  const content = useSelector((s) => s?.contents.content);
  // console.log("🚀 ~ HomePlayer ~ content:", content)

  useEffect(() => {
    if (currentTime) {
      const hideTimestamp = timeStamps?.find((timestamp) => {
        if (
          playerRef?.current?.getCurrentTime() >=
            timeStringToSeconds(timestamp?.startTime) &&
          playerRef?.current?.getCurrentTime() <=
            timeStringToSeconds(timestamp?.endTime) &&
          timestamp.status === "hide"
        ) {
          return timestamp;
        }
      });

      const forecePlayTimestamp = timeStamps?.find((timestamp) => {
        if (
          playerRef?.current?.getCurrentTime() >=
            timeStringToSeconds(timestamp?.startTime) &&
          playerRef?.current?.getCurrentTime() <=
            timeStringToSeconds(timestamp?.endTime) &&
          timestamp.status == "force play"
        ) {
          return timestamp;
        }
      });

      if (hideTimestamp) {
        if (
          currentTime >= timeStringToSeconds(hideTimestamp.startTime) &&
          currentTime <= timeStringToSeconds(hideTimestamp.endTime)
        ) {
          playerRef.current.seekTo(
            timeStringToSeconds(hideTimestamp?.endTime) + 1,
            "seconds"
          );
        }
      }
      if (forecePlayTimestamp) {
        if (
          currentTime.toString().split(".")[0] ==
          timeStringToSeconds(forecePlayTimestamp?.startTime)
        ) {
          playerRef.current.seekTo(
            timeStringToSeconds(forecePlayTimestamp?.endTime) + 1,
            "seconds"
          );
        }
      }
    }
  }, [currentTime, timeStamps]);

  useEffect(() => {
    d(
      getContentsAsyncThunk({
        // sortBy: "updatedAt:desc",
        mimeType: "video",
      })
    );
  }, [d]);

  // const contents = useSelector((s) => s?.contents?.contents) ?? {};

  //////////////////////////////////////////////////////////////////////
  const [editTimeStamps, setEditTimeStamps] = useState([]);

  // const handleEditTimestamp = (index, field, value) => {
  //   setEditTimeStamps((previousTimeStamps) => {
  //     let updatedTimestamps = [...previousTimeStamps];
  //     if (!updatedTimestamps[index]) {
  //       updatedTimestamps[index] = {};
  //     }
  //     updatedTimestamps[index][field] = value;
  //     return (updatedTimestamps);
  //   });
  //   console.log(editTimeStamps)
  // };

  const handleEditTimestamp = (index, field, value) => {
    setEditTimeStamps(() => {
      // Create a shallow copy of the array
      let updatedTimestamps = [...timeStamps];

      // Create a deep copy of the object at the specified index
      if (updatedTimestamps[index]) {
        updatedTimestamps[index] = { ...updatedTimestamps[index] };
      } else {
        updatedTimestamps[index] = {};
      }

      // Update the field with the new value
      updatedTimestamps[index][field] = value;

      return updatedTimestamps;
    });
    console.log(editTimeStamps);
};

  // const handleUpdateTimestamp = (id, editedDataArray) => {
  //   console.log(editedDataArray)
  //   if (editedDataArray) {
  //     editedDataArray.forEach((editedData) => {
  //       handleUpdateTimeStamps(id, editedData);
  //     });
  //   }
  // };


  const timePattern = /^\d{2}:\d{2}:\d{2}$/;

  const handleUpdateTimestamp = (id, editedDataArray) => {
    let isValid = true;
    console.log(editedDataArray);
    if (editedDataArray) {
        const editedData = editedDataArray.find(data => data.id === id);
        if(!timePattern.test(editedData.startTime) || !timePattern.test(editedData.endTime)){
          isValid = false
        }
        if (editedData && isValid) {
            handleUpdateTimeStamps(id, editedData);
        } else if(!isValid) {
            toast.error("Error! One or More of the timestamps do not follow the HH:MM:SS format. Please edit and ensure that it does.")
            // console.error(`No entry found with id: ${id}`);
        }
    }
};

  const handleUpdateTimeStamps = (id, updatedData) => {
    // d(updateTimeStampAsyncThunk({ id, data: updatedData }));
    console.log("id in question is: ",id)
    
    try {
      d(
        updateTimeStampAsyncThunk({
          id: id,
          data: {
            startTime: updatedData?.startTime,
            endTime: updatedData?.endTime,
            name: updatedData?.name,
            status: updatedData?.status,
            description: updatedData?.description,
            contentId: content?.id,
          }})
      )
      toast.success("Timestamps Updated Successfully!")
    } catch (error) {
      toast.error("Error! couldn't update timestamps. Please try again.")
    }
    
    
  };

  //////////////////////
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (copied) setCopied(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, [copied]);

  // chapter AI controls
  // const recognition = useRef(null);
  // console.log("🚀 ~ HomePlayer ~ recognition:", recognition);

  // const [startRecognition, setStartRecognition] = useState(false)
  const currentTimeRef = useRef();
  const timeStampsRef = useRef();

  useEffect(() => {
    currentTimeRef.current = currentTime
  }, [currentTime])

  useEffect(() => {
    timeStampsRef.current = timeStamps
  }, [timeStamps])

  useEffect(() => {
    if (annyang) {
      console.log('Annyang is available.');

      // Define the commands with synonyms
      const commands = {
        'start': () => { console.log('Command recognized: start'); handleBeginning(); },
        'begin': () => { console.log('Command recognized: begin'); handleBeginning(); },
        'commence': () => { console.log('Command recognized: commence'); handleBeginning(); },
        'initiate': () => { console.log('Command recognized: initiate'); handleBeginning(); },
        'next': () => { console.log('Command recognized: next'); handleNextChapter(); },
        'forward': () => { console.log('Command recognized: forward'); handleNextChapter(); },
        'proceed': () => { console.log('Command recognized: proceed'); handleNextChapter(); },
        'back': () => { console.log('Command recognized: back'); handleBackChapter(); },
        'previous': () => { console.log('Command recognized: previous'); handleBackChapter(); },
        'reverse': () => { console.log('Command recognized: reverse'); handleBackChapter(); },
        'end': () => { console.log('Command recognized: end'); handleEndChapter(); },
        'finish': () => { console.log('Command recognized: finish'); handleEndChapter(); },
        'terminate': () => { console.log('Command recognized: terminate'); handleEndChapter(); },
        'conclude': () => { console.log('Command recognized: conclude'); handleEndChapter(); },
        'stop': () => { console.log('Command recognized: stop'); handleEndChapter(); }
      };

      // Add the commands to annyang
      annyang.addCommands(commands);
      console.log('Commands added to Annyang:', commands);

      // Add callback for the result event
      annyang.addCallback('result', handleSpeechRecognitionResult);
      annyang.addCallback('start', () => console.log('Annyang started'));
      annyang.addCallback('end', () => console.log('Annyang ended'));
      annyang.addCallback('error', (err) => console.error('Annyang error:', err));
      annyang.addCallback('resultNoMatch', (phrases) => console.log('No match for phrases:', phrases));
      console.log('Callbacks for Annyang events added.');
    } else {
      console.error('Annyang is not available in this browser.');
    }
  }, []);

  const startSpeechRecognition = () => {
    setIsListening(true);
    if (annyang) {
      console.log('Starting speech recognition...');
      annyang.start({ autoRestart: false, continuous: false });
    }

    // Automatically stop listening after 5 seconds
    setTimeout(() => {
      stopSpeechRecognition();
    }, 5000);
  };

  const stopSpeechRecognition = () => {
    setIsListening(false);
    if (annyang) {
      // console.log('Stopping speech recognition...');
      // annyang.abort();
      console.log('Pausing speech recognition...');
      annyang.pause();
    }
  };

  const handleSpeechRecognitionResult = (userSaid) => {
    const transcript = userSaid[0].toLowerCase();
    console.log("🚀 ~ handleSpeechRecognitionResult ~ transcript:", transcript);

    // Process the transcript to determine the best matching command
    const command = processTranscript(transcript);
    console.log(`Recognized command: ${command}`);

    switch (command) {
      case 'start':
        handleBeginning();
        break;
      case 'next':
        handleNextChapter();
        break;
      case 'back':
        handleBackChapter();
        break;
      case 'end':
        handleEndChapter();
        break;
      default:
        console.log(`Unknown command: ${transcript}`);
        break;
    }
  };

  const processTranscript = (transcript) => {
    const commands = {
      "start": ["start", "begin", "commence", "initiate", "commencement", "initiation", "go to start"],
      "next": ["next", "forward", "proceed", "proceeding", "following"],
      "back": ["back", "previous", "reverse", "return", "go back", "backward"],
      "end": ["end", "finish", "terminate", "conclude", "conclusion", "stop", "go to end"],
    };

    let bestMatch = null;
    let bestScore = 0;

    for (const [command, keywords] of Object.entries(commands)) {
      keywords.forEach((keyword) => {
        const score = similarity(transcript, keyword);
        console.log(`Comparing "${transcript}" with "${keyword}", similarity score: ${score}`);
        if (score > bestScore) {
          bestScore = score;
          bestMatch = command;
        }
      });
    }

    // Set a similarity threshold
    const threshold = 0.6;
    if (bestScore >= threshold) {
      return bestMatch;
    } else {
      console.log(`No command found matching "${transcript}" with sufficient confidence.`);
      return null;
    }
  };

  // Function to calculate similarity score between two strings
  const similarity = (str1, str2) => {
    const len1 = str1.length;
    const len2 = str2.length;
    const maxLen = Math.max(len1, len2);
    if (maxLen === 0) return 1; // Both strings are empty

    const distance = levenshteinDistance(str1, str2);
    return 1 - distance / maxLen;
  };

  // Function to calculate Levenshtein distance between two strings
  const levenshteinDistance = (str1, str2) => {
    const matrix = [];
    const len1 = str1.length;
    const len2 = str2.length;

    for (let i = 0; i <= len1; i++) {
      matrix[i] = [i];
    }

    for (let j = 0; j <= len2; j++) {
      matrix[0][j] = j;
    }

    for (let i = 1; i <= len1; i++) {
      for (let j = 1; j <= len2; j++) {
        const cost = str1[i - 1] === str2[j - 1] ? 0 : 1;
        matrix[i][j] = Math.min(
          matrix[i - 1][j] + 1,
          matrix[i][j - 1] + 1,
          matrix[i - 1][j - 1] + cost
        );
      }
    }

    return matrix[len1][len2];
  };




  // useEffect(() => {
  //   recognition.current = new window.webkitSpeechRecognition();
  //   recognition.current.continuous = false;
  //   recognition.current.interimResults = false;
  //   recognition.current.lang = "en-US";
  //   recognition.current.onresult = handleSpeechRecognitionResult;
  // }, [recognition]);

  // const handleSpeechRecognitionResult = (event) => {
  //   const transcript = event.results[0][0].transcript.toLowerCase();
  //   console.log("🚀 ~ handleSpeechRecognitionResult ~ transcript:", transcript);
  //   const commands = [
  //     "start", "begin", "commence", "initiate",
  //     "next", "forward", "proceed",
  //     "back", "previous", "reverse",
  //     "end", "finish", "terminate", "conclude",
  //     "plus", "also"
  //   ];

  //   let bestMatch = null;
  //   let bestScore = 0;

  //   commands.forEach((command) => {
  //     const score = similarity(transcript, command);
  //     if (score > bestScore) {
  //       bestScore = score;
  //       bestMatch = command;
  //     }
  //   });
  //   console.log("🚀 ~ handleSpeechRecognitionResult ~ commands:", commands);

  //   const threshold = 0.25;
  //   if (bestScore >= threshold) {
  //     switch (bestMatch) {
  //       case "start":
  //       case "begin":
  //       case "commence":
  //       case "initiate":
  //         handleBeginning();
  //         break;
  //       case "forward":
  //       case "proceed":
  //       case "next":
  //         handleNextChapter();
  //         break;
  //       case "previous":
  //       case "reverse":
  //       case "back":
  //         handleBackChapter();
  //         break;
  //       case "finish":
  //       case "terminate":
  //       case "conclude":
  //       case "and":
  //       case "end":
  //         handleEndChapter();
  //         break;
  //       default:
  //         break;
  //     }
  //   }
  // };

  // // Function to calculate similarity score between two strings
  // const similarity = (str1, str2) => {
  //   const len1 = str1.length;
  //   const len2 = str2.length;
  //   const maxLen = Math.max(len1, len2);
  //   if (maxLen === 0) return 1; // Both strings are empty

  //   const distance = levenshteinDistance(str1, str2);
  //   return 1 - distance / maxLen;
  // };

  // // Function to calculate Levenshtein distance between two strings
  // const levenshteinDistance = (str1, str2) => {
  //   const matrix = [];
  //   const len1 = str1.length;
  //   const len2 = str2.length;

  //   for (let i = 0; i <= len1; i++) {
  //     matrix[i] = [i];
  //   }

  //   for (let j = 0; j <= len2; j++) {
  //     matrix[0][j] = j;
  //   }

  //   for (let i = 1; i <= len1; i++) {
  //     for (let j = 1; j <= len2; j++) {
  //       const cost = str1[i - 1] === str2[j - 1] ? 0 : 1;
  //       matrix[i][j] = Math.min(
  //         matrix[i - 1][j] + 1,
  //         matrix[i][j - 1] + 1,
  //         matrix[i - 1][j - 1] + cost
  //       );
  //     }
  //   }

  //   return matrix[len1][len2];
  // };

  // const startSpeechRecognition = () => {
  //   setIsListening(true);
  //   recognition.current.start();
  //   // Automatically stop listening after 5 seconds
  //   setTimeout(() => {
  //     stopSpeechRecognition();
  //   }, 5000);
  // };

  // const stopSpeechRecognition = () => {
  //   setIsListening(false);
  //   recognition.current.stop();
  // };

  const handleBeginning = () => {
    playerRef.current.seekTo(0);
  };
  // Function to find the index of the current chapter
  const findCurrentChapterIndex = (currentTime, timeStamps) => {
    const currentIndex = timeStamps?.findIndex((chapter) => {
      const startTimeInSeconds = timeStringToSeconds(chapter.startTime);
      const endTimeInSeconds = timeStringToSeconds(chapter.endTime);
      return (
        currentTime >= startTimeInSeconds && currentTime <= endTimeInSeconds
      );
    });
    return currentIndex;
  };

  // Function to handle skip to the next chapter
  const handleNextChapter = async () => {
    console.log("calll next func");
    console.log("🚀 ~ handleNextChapter ~ location:", location);
    console.log(
      "🚀 ~ handleNextChapter ~ location?.search.length > 1:",
      location?.search.length > 1
    );
    if (location?.search.length > 1) {
      history(`/player/${params.id}`);
    } else {
      console.log("elseeeee  call next");
      const currentIndex = await findCurrentChapterIndex(
        currentTimeRef.current,
        timeStampsRef.current
      );
      console.log("🚀 ~ handleNextChapter ~ currentIndex:", currentIndex);
      if (currentIndex !== -1) {
        const currentChapterEndTime = timeStringToSeconds(
          timeStampsRef.current[currentIndex].endTime
        );
        playerRef.current.seekTo(currentChapterEndTime);
      }
    }
  };

  // Function to handle start from the Back chapter
  const handleBackChapter = () => {
    if (location?.search.length > 1) {
      history(`/player/${params.id}`);
    } else {
      const currentIndex = findCurrentChapterIndex(currentTimeRef.current, timeStampsRef.current);
      if (currentIndex > 0) {
        const previousChapterStartTime = timeStringToSeconds(
          timeStampsRef.current[currentIndex - 1].startTime
        );
        playerRef?.current?.seekTo(previousChapterStartTime);
      }
    }
  };

  const handleRepeatChapter = () => {
    if (location?.search.length > 1) {
      history(`/player/${params.id}`);
    } else {
      // TODO: Implement repeat chapter logic
      const currentIndex = findCurrentChapterIndex(currentTime, timeStamps);
      if (currentIndex !== -1) {
        const currentChapterStartTime = timeStringToSeconds(
          timeStamps[currentIndex].startTime
        );
        playerRef?.current?.seekTo(currentChapterStartTime);
      }
    }
  };

  // Function to handle starting from the end of the last chapter
  const handleEndChapter = () => {
    // const lastIndex = timeStamps.length - 1;
    // const lastChapterEndTime = timeStringToSeconds(
    //   timeStampsRef.current[lastIndex].endTime
    // );
    playerRef?.current?.seekTo(playerRef.current.getDuration());
  };

  ////////////////////////////////////////////////////////////////
  const history = useNavigate();

  const handleChapterClick = (chapter) => {
    if (params.id) {
      history(`/player/${params.id}`);
    }
    playerRef.current.seekTo(timeStringToSeconds(chapter.startTime));
  };

  //////////////////////////////////////
  //////////////////////////////////////
  //////////////////////////////////////
  //////////////////////////////////////
  // a

  const [widthOFBar, setWidthOFBar] = useState(0);
  const [seekTime, setSeekTime] = useState(0);
  console.log("🚀 ~ HomePlayer ~ seekTime:", seekTime)
  const [showHoverInfo, setShowHoverInfo] = useState(false);

  useEffect(() => {
    if (playerRef?.current) {
      setWidthOFBar(
        (playerRef?.current?.getCurrentTime() /
          playerRef?.current?.getDuration()) *
          100
      );
    }
  }, [playerRef?.current?.getCurrentTime()]);

  const handleMouseMove = (e) => {
    const rect = e.target.getBoundingClientRect();
    console.log("🚀 ~ handleMouseMove ~ rect:", rect)
    const x = e.clientX - rect.left;
    const duration = playerRef?.current?.getDuration();
    let position;

    // Check if the mouse is over the red progress bar
    if (e.target.style.background == "red") {
      position = ((x / rect.width) * widthOFBar) / 100; // Calculate position relative to red bar width
    } 
    // else {
    //   position = x / rect.width;
    // }
    
    position = x / rect.width;


    const time = duration * position;
    console.log("🚀 ~ handleMouseMove ~ time:", time)
    setSeekTime(time);
    setShowHoverInfo(true);
  };

  const handleMouseLeave = () => {
    setShowHoverInfo(false);
  };
  // Function to parse time from "HH:MM:SS" format to seconds
  const parseTime = (timeString) => {
    const parts = timeString?.split(":");
    return +parts?.[0] * 3600 + +parts?.[1] * 60 + +parts?.[2];
  };

  const formatTime = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = Math.floor(timeInSeconds % 60);

    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = seconds.toString().padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };

  /////////////////////////////////////////////////
  /////////////////////////////////////////////////
  /////////////////////////////////////////////////
  /////////////////////////////////////////////////
  // model
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [timeRange, setTimeRange] = useState({
    id: null,
    startTime: "",
    endTime: "",
    name: "",
    status: "",
    description: "",
  });

  const openModal = (timestamp) => {
    setIsModalOpen(true);
    setTimeRange({
      id: timestamp.id, // Assuming timestamp object has an id property
      startTime: timestamp.startTime,
      endTime: timestamp.endTime,
      name: timestamp.name,
      status: timestamp.status,
      description: timestamp.description,
    });
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };





  // State to track fullscreen mode
  const [isFullScreen, setIsFullScreen] = useState(false);

  useEffect(() => {
    const handleFullScreenChange = () => {
      setIsFullScreen(document.fullscreenElement !== null);
    };

    document.addEventListener("fullscreenchange", handleFullScreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, []);

  let ServerDomain = process.env.REACT_APP_SERVER_DOMAIN_URL;

  return (
    <div>
      <div className="Play">
        <div className="row">
          <div className="col-xl-12 col-lg-12" id="videosection">
            <div className="channel_details">
              <p style={{ position: "relative" }}>
                <ReactPlayer
                  ref={playerRef}
                  url={parseUrlFromModel(content)}
                  width="100%"
                  height="100%"
                  muted={isMuted}
                  controls={true}
                  playing={true}
                  className="logo_img"
                  onPlay={handlePlayerReady}
                  onProgress={handleProgress}
                  autoPlay={true}
                  volume={0.1}
                  pip={true}
                  onFullScreenChange={(isFullScreen) =>
                    setIsFullScreen(isFullScreen)
                  } 
                  config={{
                    file: {
                      forceVideo: true,
                      attributes: {
                        controlsList: "nodownload",
                      },
                    },
                  }}

                />

                {/* gray area to show hover time */}
                <div
                  onMouseMove={handleMouseMove}
                  onMouseLeave={handleMouseLeave}
                  style={{
                    height: "2px",
                    background: "transparent",
                    zIndex: 10,
                    width: "98%",
                    left: "15px",
                    position: "absolute",
                    bottom: "31px",
                  }}
                  className="transparent"
                ></div>
                {/* show hover time and thumbnail */}
                {showHoverInfo && seekTime != null && (
                  <div
                    style={{
                      position: "absolute",
                      left: `${
                        (seekTime / playerRef?.current?.getDuration()) * 100
                      }%`,
                      color: "black",
                      display:"flex",
                      alignItems:"center",
                      gap:"1px",
                      flexDirection:"column"
                    }}
                  >
                    {formatTime(Math.floor(seekTime))}
                    {timeStamps.map((timestamp, index) => {
                      const startTime = parseTime(timestamp.startTime);
                      const endTime = parseTime(timestamp.endTime);
                      // Check if seekTime falls within this timestamp range
                      if (seekTime >= startTime && seekTime <= endTime) {
                        return (
                          <img
                          key={index}
                          src={
                            timestamp && timestamp.chapterThunbnail
                              ? `${ServerDomain}${timestamp.chapterThunbnail
                                  .split("/")
                                  .pop()}`
                              : "images/Card-thumbnail.png"
                          }
                          alt="Thumbnail"
                          // width="100"
                          // height="100"
                          className="Thumbnail-img"
                        />
                        );
                      }
                      return null;
                    })}
                  </div>
                )}
                {/* red area to show current time */}
                <div
                  // onMouseMove={handleMouseMove}
                  // onMouseLeave={handleMouseLeave}
                  className="red-bar"
                  style={{
                    height: "2px",
                    background: "transparent",
                    width: `${widthOFBar}%`,
                    maxWidth: "97.6%",
                    // left: "15px",
                    position: "absolute",
                    bottom: "33px",
                    zIndex: "999",
                  }}
                ></div>
                {/* Chunks based on startTime */}

                <div
                className="black-bar"
                  style={{
                    width: "97.7%",
                    position: "relative",
                    // left: "16px",
                    bottom: "31px",
                    background: timeStamps?.length > 0 ? "black" : "transparent",
                    height: "5px",
                  }}
                >
                  {timeStamps.map((timestamp, index) => (
                    <div
                      key={index}
                      style={{
                        position: "absolute",
                        bottom:"1px",
                        background: colorslist[index % colorslist.length],
                        left: `${
                          (parseTime(timestamp.startTime) /
                            playerRef?.current?.getDuration()) *
                          100
                        }%`,
                        width: `${
                          ((parseTime(timestamp.endTime) -
                            parseTime(timestamp.startTime)) /
                            playerRef?.current?.getDuration()) *
                          100
                        }%`,
                        height: "3px",
                        cursor: "pointer",
                      }}
                      onClick={() => openModal(timestamp)}
                    ></div>
                  ))}
                </div>

                {/* Control buttons */}
                <div className="control-buttons voice-position">
                  {isListening ? (
                    <Button
                      onClick={stopSpeechRecognition}
                      className="voice-btn"
                    >
                      <FaMicrophone />
                      {/* <img className="words-around-mic" src={WordsAroundMic} alt="words-around-mic"/>  */}
                      <WordsAroundMic color='white'/>

                    </Button>
                  ) : (
                    <Button
                      onClick={startSpeechRecognition}
                      className="voice-btn"
                    >
                      <FaMicrophoneSlash className="mute-svg" />
                      {/* <img className="words-around-mic" src={WordsAroundMic} alt="words-around-mic"/>  */}

                      <WordsAroundMic color='#fff'/>
                    </Button>
                  )}




                </div>
              </p>
              <div>
                <div className="name-btn-flex">
                  <p className="name">
                    {content?.name ?? content?.originalName}
                    {content && <span className="views-by-name-side"><span className="views-eye-container"><FaEye size={23} color="red"/></span>({content.viewsCount})</span>}
                  </p>
                  {/* Share button */}
                  <Button
                    onClick={() => {
                      const shareableUrl = generateShareableUrl(currentTime);
                      copyToClipboard(shareableUrl);
                    }}
                  >
                    <button
                      className="btn-primary"
                      onClick={() => setCopied(true)}
                      css={{
                        appearance: "none",
                        padding: 8,
                        border: 0,
                        outline: 0,
                        cursor: "pointer",
                      }}
                    >
                      <div
                        css={{
                          position: "relative",
                          height: 16,
                          width: 16,
                        }}
                      >
                        <span>Share</span>
                        {copied == false ? (
                          <Clippy
                            css={{
                              color: colors.gray[8],
                              position: "absolute",
                              top: 0,
                              left: 0,
                              strokeDasharray: 50,
                              strokeDashoffset: copied ? -50 : 0,
                              transition: "all 300ms ease-in-out",
                            }}
                          />
                        ) : (
                          <Check
                            css={{
                              color: colors.green[5],
                              position: "absolute",
                              top: 0,
                              left: 0,
                              strokeDasharray: 50,
                              strokeDashoffset: copied ? 0 : -50,
                              transition: "all 300ms ease-in-out",
                            }}
                          />
                        )}
                      </div>
                    </button>
                  </Button>
                </div>

                {/* Control buttons */}
                <div className="control-buttons d-flex mt-2">
                  <Button onClick={() => playerRef.current.seekTo(0)}>
                    Start
                  </Button>
                  <Button onClick={handleBackChapter}>Back</Button>
                  <Button onClick={handleNextChapter}>Next</Button>
                  <Button onClick={handleRepeatChapter}>Repeat</Button>
                  <Button onClick={handleEndChapter}>End</Button>
                </div>
              </div>
            </div>
          </div>
          {user?.role == "admin" ? (
            <div className="col-xl-12 col-lg-12" id="VideosCollection">
              <div className="f-container">
                <div className="collection-outer-body Scroller-table">
                  {timeStamps &&
                    timeStamps?.map((item, i) => (
                      <ChapterListCard
                        i={i}
                        key={item?.id}
                        item={item}
                        editTimeStamps={editTimeStamps}
                        handleEditTimestamp={handleEditTimestamp}
                        handleUpdateTimestamp={handleUpdateTimestamp}
                      />
                    ))}
                </div>
              </div>
            </div>
          ) : (
            <div className="col-xl-12 col-lg-12" id="VideosCollection">
              <div className="Upper_portion">
                <div className="row">
                  {timeStamps &&
                    timeStamps?.map((item, i) => (
                      <ChapterReactCard
                        i={i}
                        item={item}
                        key={item?.id}
                        editTimeStamps={editTimeStamps}
                        handleEditTimestamp={handleEditTimestamp}
                        handleUpdateTimestamp={handleUpdateTimestamp}
                        // child to parent props
                        handleChapterClick={handleChapterClick}
                      />
                    ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Modal */}
      <TimeStampUpdateModel
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        content={content}
        handlePlayerReady={handlePlayerReady}
        handleProgress={handleProgress}
        playerRef={playerRef}
        timeRange={timeRange}
        setTimeRange={setTimeRange}
        timeStringToSeconds={timeStringToSeconds}
        parseTime={parseTime}
        timeStamps={timeStamps}
        updateTimeStamps={updateTimeStamps}
        setUpdateTimeStamps={setUpdateTimeStamps}
      />
    </div>
  );
};

export default HomePlayer;
