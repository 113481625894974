/** @format */

import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';

import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import SignLanguageOutlinedIcon from '@mui/icons-material/SignLanguageOutlined';
import SignLanguageIcon from '@mui/icons-material/SignLanguage';
import { FaEye } from 'react-icons/fa';

import {
  updateContentReactAsyncThunk,
  getContentsAsyncThunk,
} from '../../redux/pagesSlices/contentSlice';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { parseUrlFromModelThumbnail } from '../../helpers/asset';
import { getTimeStampsAsyncThunk } from '../../redux/pagesSlices/timeStampSlice';

const Videoscard = ({ data }) => {
  const d = useDispatch();

  const timeStamps = useSelector((s) => s?.timeStamp?.timeStamps?.results);
  const userId = JSON.parse(localStorage.getItem('user'))?.id;
  console.log('🚀 ~ Videoscard ~ userId:', userId);
  let userIp = localStorage.getItem('userIp');
  console.log('userIp>>>>>>>', userIp);
  // console.log("🚀 ~ Videoscard ~ userId:", userId);
  let BasePath = process.env.REACT_APP_contentBasePath;

  // useEffect(() => {
  //   d(
  //     getTimeStampsAsyncThunk({
  //       contentId: data?.id,
  //       populate: "contentId",
  //       sortBy: "name:asc",
  //       limit: 60,
  //     })
  //   );
  // }, []);

  // Calculate total counts
  const videoReactionsCounts = (data.reactions || []).reduce(
    (counts, reaction) => {
      counts[reaction.type] = (counts[reaction.type] || 0) + 1;
      return counts;
    },
    {}
  );

  // const totalVideoHeartCount = timeStamps.reduce((count, chapter) => {
  //   const chapterReacts = chapter.reactions.reduce((heartCount, reaction) => {
  //     if(reaction.type === 'heart'){
  //       return heartCount + 1
  //     }
  //     return heartCount
  //   }, 0)

  //   if(chapterReacts)
  //     return chapterReacts + count

  //   return count
  // }, videoReactionsCounts.heart)

  // const adminId = JSON.parse(localStorage.getItem("user"))?.id;
  // console.log("🚀 ~ Videoscard ~ userId:", adminId);
  // let userIp = localStorage.getItem("adminIp");
  // console.log("userIp>>>>>>>", userIp);

  const handleReact = async (id, reactionType) => {
    console.log('🚀 ~ handleReact ~ id, reactionType:', id, reactionType);
    try {
      if (!userId) {
        toast.warn('Please log in to react.');
        return;
      }

      const alreadyReacted = data?.reactions.some(
        (item) =>
          item.type == reactionType &&
          (item.user == userId || item.ip == userIp)
      );
      console.log('🚀 ~ handleReact ~ alreadyReacted:', alreadyReacted);

      if (alreadyReacted) {
        // const requestData = userId
        //   ? { reactionType, user: userId, ip: "" }
        //   : { reactionType, ip: userIp, user: "" };
        await deleteContentReact(id, reactionType);
      } else {
        await d(updateContentReactAsyncThunk({ id, data: { reactionType } }));
      }
    } catch (error) {
      console.error('Error updating reaction:', error);
      toast.error('Error updating reaction.');
    }
  };

  async function deleteContentReact(id, data) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('app-access-token'));
      const response = await fetch(`${BasePath}/user/contents/react/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ reactionType: data }),
      });

      if (!response.ok) {
        throw new Error('Error deleting content reaction');
      }

      const responseData = await response.json();

      // Dispatch an action to fetch updated content list after successful deletion
      if (response.status === 200) {
        d(getContentsAsyncThunk({ sortBy: 'name:asc', mimeType: 'video' }));
        // Optionally, you can display a success toast message here
      }

      return responseData;
    } catch (error) {
      console.error('Error deleting content reaction:', error);
      throw error;
    }
  }

  let ServerDomain = process.env.REACT_APP_SERVER_DOMAIN_URL;
  // console.log("🚀 ~ Videoscard ~ ServerDomain:", ServerDomain)
  // console.log(
  //     data && data?.thumbnailPath
  //       ? `${ServerDomain}${data?.thumbnailPath
  //           .split("/")
  //           .pop()}`
  //       : "images/Card-thumbnail.png"
  // )
  return (
    <div className='Cards-box'>
      <div className='Latest-tab'>
        <Card>
          <Link to={`/player/${data?.id}`} className='play_button'>
            <div className='Card_img'>
              {/* <img src={thumbnail ?? "images/Card-thumbnail.png"} alt="Thumbnail" /> */}
              <img
                // src={
                //   parseUrlFromModel(data?.thumbnail)
                //     ? parseUrlFromModel(data?.thumbnail)
                //     : "images/Card-thumbnail.png"
                // }
                // src="images/Card-thumbnail.png" alt=""

                src={
                  data && data.thumbnailPath
                    ? // ? `${ServerDomain}${data.thumbnailPath.split("/").pop()}`
                      parseUrlFromModelThumbnail(data)
                    : 'images/Card-thumbnail.png'
                }
                alt=''
              />
              <Card.Title>{data?.originalName ?? data?.name}</Card.Title>
            </div>
          </Link>

          <Card.Body>
            {/* Display reaction buttons */}
            <div className='reaction-btns'>
              <button
                onClick={() => handleReact(data.id, 'heart')}
                className='Likes-btn'>
                {data.reactions &&
                data.reactions?.some(
                  (reaction) =>
                    //reaction.type === 'heart' && reaction.user === userId
                    reaction.type === 'heart'
                ) ? (
                  <FavoriteOutlinedIcon />
                ) : (
                  <FavoriteBorderOutlinedIcon />
                )}
              </button>
              <div>({videoReactionsCounts.heart || 0})</div>
              <button
                onClick={() => handleReact(data.id, 'clap')}
                className='Likes-btn clap-btn'>
                {data.reactions &&
                data.reactions.some(
                  (reaction) =>
                    //reaction.type == 'clap' && reaction.user == userId
                    reaction.type == 'clap'
                ) ? (
                  <SignLanguageIcon />
                ) : (
                  <SignLanguageOutlinedIcon />
                )}
              </button>
              <div>({videoReactionsCounts.clap || 0})</div>

              <button className='Likes-btn eye-icon ms-1'>
                {' '}
                <FaEye />
              </button>
              <div>({data?.viewsCount || 0})</div>
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default Videoscard;
