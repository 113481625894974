import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getPerformerAsyncThunk } from "../redux/pagesSlices/performerSlice";
import NoDataFound from "../components/nodatafound";
import Loadingdata from "../components/loadingdata";

const PerfomerListItem = () => {
  const params = useParams();
  console.log("🚀 ~ PerfomerListItem ~ params:", params);
  const d = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    d(getPerformerAsyncThunk(params?.id)).finally(() => setLoading(false));
  }, [params.id, d]);

  const getData = useSelector((state) => state?.performer?.performerById);
  console.log("🚀 ~ PerfomerListItem ~ getData:", getData);

  if (loading) {
    return <Loadingdata />;
  }

  // Handle no data state
  if (!getData) {
    return <NoDataFound nodataTitle="No Data Found" />;
  }

  return (
    <div className="performers_listing_details mt-5">
      <div className="container">
        <div className="performers-page">
          <div className="performers-list-container">
            <h2 className="pb-4">Details</h2>
            {getData && (
              <div className="performer_content">
                <p>
                  <strong>Name:</strong>{" "}
                  {getData?.performer?.name?.slice(0, 25) +
                    (getData?.performer?.name?.length > 25 ? "..." : "") ||
                    "N/A"}
                </p>
                <p>
                  <strong>Email:</strong>{" "}
                  {getData?.performer?.email?.slice(0, 25) +
                    (getData?.performer?.email?.length > 25 ? "..." : "") ||
                    "N/A"}
                </p>
                <p>
                  <strong>Bio:</strong>{" "}
                  {getData?.performer?.bio?.slice(0, 25) +
                    (getData?.performer?.bio?.length > 25 ? "..." : "") ||
                    "N/A"}
                </p>
                <p>
                  <strong>Facebook:</strong>{" "}
                  {getData?.performer?.fb?.slice(0, 25) +
                    (getData?.performer?.fb?.length > 25 ? "..." : "") || "N/A"}
                </p>
                <p>
                  <strong>Instagram:</strong>{" "}
                  {getData?.performer?.instagram?.slice(0, 25) +
                    (getData?.performer?.instagram?.length > 25 ? "..." : "") ||
                    "N/A"}
                </p>
                <p>
                  <strong>YouTube:</strong>{" "}
                  {getData?.performer?.youtube?.slice(0, 25) +
                    (getData?.performer?.youtube?.length > 25 ? "..." : "") ||
                    "N/A"}
                </p>
                <p>
                  <strong>SR:</strong>{" "}
                  {getData?.performer?.sr?.slice(0, 25) +
                    (getData?.performer?.sr?.length > 25 ? "..." : "") || "N/A"}
                </p>
              </div>
            )}
          </div>
        </div>

        <div className="Form-table mb-4">
          <div className="table-wrapper">
            <table>
              <thead>
                <tr>
                  <th>Timestamp</th>
                  <th>StartTime</th>
                  <th>EndTime</th>
                  <th>Content Name</th>
                </tr>
              </thead>

              <tbody>
                {Array.isArray(getData?.timestamps) &&
                getData?.timestamps?.length > 0 ? (
                  getData?.timestamps?.map((timestamp, index) => (
                    <tr key={index}>
                      <td>{timestamp?.name}</td>
                      <td>{timestamp?.startTime}</td>
                      <td>{timestamp?.endTime}</td>
                      <td>{timestamp?.contentId?.name || "N/A"}</td>
                    </tr>
                  ))
                ) : (
                  <NoDataFound nodataTitle="No Data Found" />
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PerfomerListItem;
