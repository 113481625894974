import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Loadingdata from "../../components/loadingdata";
import NotFound from "../../components/nodatafound.jsx";
import "../../App.scss";

const PaginationCompo = ({
  children,
  populate,
  listingView,
  dotRange = 5,
  limitArray = [5, 10, 20],
  asyncThunk,
  fetchInitialData = true,
  reducer,
  dataKey,
  action,
  Card,
  componentProps,
}) => {
  const dispatch = useDispatch();
  const [rowPerPage, setRowPerPage] = useState(10);
  const [initialRender, setInitialRender] = useState(false);

  const paginationParams = useSelector((state) => state[reducer]?.[dataKey]);
  // console.log("🚀 ~ paginationParams:", paginationParams)

  const serverParams = useSelector(
    (state) => state[reducer]?.paramsForThunk[action]
  );
  const loading = useSelector((state) => state[reducer]?.loadings[action]);

  const [currentPage, setCurrentPage] = useState(paginationParams?.page || 1);

  // Pagination Functions
  const handleNextPage = () => {
    if (currentPage < paginationParams?.totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleLimitChange = (value) => {
    setCurrentPage(1);
    setRowPerPage(value);
  };

  const handleClickPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPagination = () => {
    const pages = [];
    const startPage = Math.max(1, currentPage - Math.floor(dotRange / 2));
    const endPage = Math.min(
      paginationParams?.totalPages,
      startPage + dotRange - 1
    );

    // Add First Page button
    if (startPage > 1) {
      pages.push(
        <button
          key={1}
          onClick={() => {
            handleClickPage(1);
          }}
        >
          1
        </button>
      );
      if (startPage > 2) {
        pages.push(<span>...</span>);
      }
    }

    // Add pages within the range
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => {
            handleClickPage(i);
          }}
          className={currentPage === i ? "active" : ""}
        >
          {i}
        </button>
      );
    }

    // Add Last Page button
    if (endPage < paginationParams?.totalPages) {
      if (endPage < paginationParams?.totalPages - 1) {
        pages.push(
          <button disabled style={{ cursor: "default" }}>
            ...
          </button>
        );
      }
      pages.push(
        <button
          key={paginationParams?.totalPages}
          onClick={() => {
            handleClickPage(paginationParams?.totalPages);
          }}
        >
          {paginationParams?.totalPages}
        </button>
      );
    }

    return pages;
  };
  // Pagination Logic end
  useEffect(() => {
    if (fetchInitialData || initialRender) {
      const newParams = {
        page: currentPage,
        limit: rowPerPage,
      };
      dispatch(
        asyncThunk({
          ...((serverParams && serverParams) ?? { populate: populate }),
          ...newParams,
        })
      );
    } else {
      setInitialRender(true);
    }
  }, [currentPage, rowPerPage]);

  // if (paginationParams?.totalPages <= 1) return null
  return (
    <>
      {loading ? (
        <Loadingdata />
      ) : (
        <>
          {children}
          {!paginationParams?.results?.length ? (
            <NotFound />
          ) : (
            paginationParams?.results?.map((collection, index) => {
              // console.log("🚀 ~ paginationParams?.results?.map ~ collection:", collection)
              return (
                <>
                  <Card
                  key={index}
                    collection={collection}
                    index={index}
                    {...componentProps}
                  />
                </>
              );
            })
          )}
        </>
      )}

      {/* {paginationParams?.totalPages > 1 && ( */}
      {paginationParams?.totalPages > 1 && (
        <div className="SetData-Btn" style={{ marginBottom: "20px" }}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <span className="span-14">Page</span>
            <div className="">
              <select
                className="div-39"
                value={paginationParams?.limit}
                onChange={(e) => handleLimitChange(e.target.value)}
              >
                {limitArray.map((limit) => (
                  <option key={limit} value={limit}>
                    {limit}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <button onClick={() => handleClickPage(1)}>
            <i className="fa-solid fa-angles-left"></i>
            First
          </button>
          <button onClick={handlePreviousPage}>
            <i className="fa-solid fa-angle-left"></i>
            Back
          </button>
          {renderPagination()}
          <button onClick={handleNextPage}>
            Next
            <i className="fa-solid fa-angle-right"></i>
          </button>
          <button onClick={() => handleClickPage(paginationParams?.totalPages)}>
            Last
            <i className="fa-solid fa-angles-right"></i>
          </button>
        </div>
      )}
    </>
  );
};

export default PaginationCompo;
