import React, { useEffect, useState, useRef } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
// import NewHeader from "../components/newHeader.jsx";
import Headertabs from "../components/headertabs.jsx";
// import LeftSideBar from "../components/LeftSideBar.jsx";
import ContentModelBody from "../components/modals/contentModel/contentModelBody.jsx";
import LeftSidebarBase from "../components/modals/leftSidebarBase/index.jsx";
import { Dropdown, Modal, Form } from "react-bootstrap";
import LibraryListView from "../components/LibraryComponents/Cards/LibraryListView.jsx";
import LibraryGridView from "../components/LibraryComponents/Cards/LibraryGridView.jsx";
// import {
//   createContentAsyncThunk,
//   getContentsAsyncThunk,
// } from "../redux/pagesSlices/contentSlice.js";

import {
  createLinkAsyncThunk,
  getMyLinksAsyncThunk,
} from "../redux/pagesSlices/linkSlice.js";

import { useSelector, useDispatch } from "react-redux";
import YoutubeLibraryListCard from "../components/LibraryComponents/Cards/YoutubeLibraryListCard.jsx";
import LibraryGridCards from "../components/LibraryComponents/Cards/LibraryGridCards.jsx";
import PaginationCompo from "../components/pagination/PaginationCompo.js";
import ListHeader from "./ListHeader.jsx";
import DataHeader from "../components/DataHeader.jsx";
import { Link } from "react-router-dom";
import Sidebar from "../components/Sidebar-lft/Sidebar.jsx";

AOS.init();
export default function YoutubeLibrary() {
  const [isListView, setIsListView] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const fileRef = useRef(null);

  const toggleView = () => {
    setIsListView(!isListView);
  };
  //   const contents = useSelector((s) => s?.contents?.contents) ?? {};
  const contents = useSelector((s) => s?.links?.myLink) ?? {};
  const d = useDispatch();
  useEffect(() => {
    // setTimeout(() => {
    d(
      getMyLinksAsyncThunk({
          sortBy: "updatedAt:desc",
        // populate:"thumbnail",
        //   mimeType: "video",
        page: 1,
        // sortBy:"name:ase",
        ...(searchTerm && { name: searchTerm }),
      })
    );
    // }, 1000);
  }, [searchTerm]);

  return (
    <div>
      {/* <div className="Sidebar" >
          <Sidebar />
        </div> */}
      <div className="studio-main-body">
        <div className="assets-header-section">
          <div className="assets-header">
            <h3>Youtube & Google Drive Files</h3>
            <div className="options">
              {isListView ? (
                <button className="btn btn-link" onClick={toggleView}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M3 11V3H11V11H3ZM3 21V13H11V21H3ZM13 11V3H21V11H13ZM13 21V13H21V21H13ZM5 9H9V5H5V9ZM15 9H19V5H15V9ZM15 19H19V15H15V19ZM5 19H9V15H5V19Z"
                      fill="#2D2F31"
                    />
                  </svg>
                </button>
              ) : (
                <button className="btn btn-link">
                  <i onClick={toggleView} className="fa-solid fa-list-ul"></i>
                </button>
              )}

              <Link to="/admin/upload">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M11 13H5V11H11V5H13V11H19V13H13V19H11V13Z"
                    fill="#2181FA"
                  />
                </svg>
              </Link>
            </div>
          </div>
          <div className="assets-top-filter">
            <div className="row">
              <div className="col-lg-12">
                <div className="search-assets">
                  <i className="fa-solid fa-magnifying-glass"></i>
                  <Form.Control
                    onChange={(e) => setSearchTerm(e.target.value)}
                    type="text"
                    placeholder="Search"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="f-container">
            <div className="collection-outer-body collectionlist">
              <div className="row">
                {isListView && (
                  <DataHeader data={["File Type", "Date Added"]} />
                )}
                <PaginationCompo
                  fetchInitialData={false}
                  // listingView={isListView ? "list" : "grid"}
                  //   Card={!isListView ? LibraryGridCards : LibraryListCard}
                  // Card={!isListView && YoutubeLibraryListCard}
                  Card={YoutubeLibraryListCard}
                  asyncThunk={getMyLinksAsyncThunk}
                  reducer={"links"}
                  dataKey={"myLink"}
                  limitArray={[10, 20, 30, 40, 50]}
                  dotRange={5}
                  action={"getMyLinksAsyncThunk"}
                />
              </div>
            </div>
          </div>
          {/* {isListView ? (
            <LibraryListView data={contents?.results} />
          ) : (
            <LibraryGridView data={contents.results} />
          )} */}
        </div>
      </div>
    </div>
  );
}
