import { React, useState } from "react";
import { Button, Container, Navbar, Dropdown, Offcanvas, Form } from "react-bootstrap";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { userLogoutAsyncThunk } from "../../redux/pagesSlices/authSlice";
import { useDispatch } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";

const Topbar = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleLinkClick = () => {
        handleClose();
    };
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");

    const dispatch = useDispatch();
    const router = useNavigate();

    const handleLogout = () => {
        dispatch(
            userLogoutAsyncThunk({
                cb: () => {
                    router("/login");
                },
            })
        );
    };


    let user = JSON.parse(localStorage.getItem("user"));
    return (
        <div className="Topbar d-lg-none">
            <Navbar expand="lg" className="bg-body-tertiary">
                <Container fluid>

                    <Button variant="light" onClick={handleShow} className="Offcanvas-btn">
                        <img src="/images/toggle.png" alt="" />
                    </Button>

                    <Offcanvas show={show} onHide={handleClose}>
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title><img src="/images/Logo.png" alt="Logo" /></Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <div className="offcanvas-flex">
                                {user?.role == "admin" && (
                                    <>
                                         <Link to="/" onClick={handleLinkClick}>
                                    <button className={`btn Nav-link ${splitLocation[1] === "" ? "active" : ""}`}>
                                        Home
                                    </button>
                                </Link>

                                        <Link to="/youtube" onClick={handleLinkClick}>
                                            <button
                                                className={`btn Nav-link  ${splitLocation[1] === "/youtube" ? "active" : ""
                                                    }`}
                                            >
                                                Youtube
                                            </button>
                                        </Link>

                                        <Link to="/admin/upload" onClick={handleLinkClick}>
                                            <button
                                                className={`btn Nav-link  ${splitLocation[1] === "/admin/upload" ? "active" : ""
                                                    }`}
                                            >
                                                Upload
                                            </button>
                                        </Link>
                                        

                                        <Link to="/admin/library" onClick={handleLinkClick}>
                                            <button
                                                className={`btn Nav-link  ${splitLocation[1] === "/admin/library" ? "active" : ""
                                                    }`}
                                            >
                                                Library
                                            </button>
                                        </Link>

                                        <Link to="/admin/youtube-library" onClick={handleLinkClick}>
                                            <button
                                                className={`btn Nav-link  ${splitLocation[1] === "/admin/youtube-library" ? "active" : ""
                                                    }`}
                                            >
                                                Youtube Library
                                            </button>
                                        </Link>

                                        <Link to="/admin/reports" onClick={handleLinkClick}>
                                            <button
                                                className={`btn Nav-link  ${splitLocation[1] === "/admin/reports" ? "active" : ""
                                                    }`}
                                            >
                                                Reports
                                            </button>
                                        </Link>
                                    </>
                                )}
                            </div>
                        </Offcanvas.Body>
                    </Offcanvas>


                    <Navbar.Brand href="" className="Navbar-logo">
                        <Link to="/">

                            <img src="/images/Logo.png" alt="Logo" /> <p>RPlayer</p>
                        </Link>
                    </Navbar.Brand>


                    <div className="Login-portion ms-0">
                        {/* <Button className="Submit">Submit Video</Button> */}

                        <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                <AccountCircleIcon />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {user && <Button>{user?.name}</Button>}
                                {user ?
                                    <Button onClick={handleLogout}>Logout</Button>
                                    :
                                    <Link to="/login">
                                        <Button>Login</Button>
                                    </Link>
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>

                    <Form className="flex-box">
                        <Form.Control
                            type="search"
                            placeholder="Search"
                            className=""
                        />
                        <Button className='icon-btn'><SearchIcon /></Button>
                    </Form>
                </Container>
            </Navbar>
        </div>
    );
};

export default Topbar;

