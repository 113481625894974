import Accordion from "react-bootstrap/Accordion";
import React, { useState } from "react";
import { createPerformerAsyncThunk } from "../redux/pagesSlices/performerSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import "./perfomer.css";
import { FileUploader } from "react-drag-drop-files";
import { imageFileTypes } from "../../src/constants";

const Perfomer = () => {
  const dispatch = useDispatch();

  const [newPerformer, setNewPerformer] = useState({
    name: "",
    description: "",
    email: "",
    fb: "",
    instagram: "",
    youtube: "",
    bio: "",
  });
  console.log("🚀 ~ Perfomer ~ newPerformer:", newPerformer);

  const [contentFiles, setContentFiles] = useState([]);
  console.log("🚀 ~ Perfomer ~ contentFiles:", contentFiles);

  const handleCreatePerformer = async (event) => {
    event.preventDefault();
    try {
      const formData = new FormData();
      contentFiles.forEach((file, index) => {
        formData.append(`content`, file);
      });

      formData.append("name", newPerformer.name);
      formData.append("description", newPerformer.description);
      formData.append("email", newPerformer.email);
      formData.append("fb", newPerformer.fb);
      formData.append("instagram", newPerformer.instagram);
      formData.append("youtube", newPerformer.youtube);
      formData.append("bio", newPerformer.bio);

      // Log FormData content
      for (const pair of formData.entries()) {
        console.log(`${pair[0]}: ${pair[1]}`);
      }

      await dispatch(createPerformerAsyncThunk({ data: formData }));
      toast.success("Performer created successfully!");
      setNewPerformer({
        name: "",
        description: "",
        email: "",
        fb: "",
        instagram: "",
        youtube: "",
        bio: "",
      });
      setContentFiles([]);
    } catch (error) {
      toast.error("Error encountered while creating Performer.");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewPerformer((prev) => ({ ...prev, [name]: value }));
  };

  const handleContentChange = (files) => {
    const fileArray = Array.from(files); // Convert FileList to Array
    setContentFiles(fileArray);
  };

  return (
    <div className="performers-page">
      <div className="performers-list-container">
        <div className="assets-table-body py-4">
          <div>
            <h2 className="text-center">Create Performer</h2>
            <form onSubmit={handleCreatePerformer} className="create-performer">
              <FileUploader
                multiple={true}
                handleChange={handleContentChange}
                name="content"
                types={imageFileTypes}
              />

              <input
                type="text"
                value={newPerformer.name}
                name="name"
                id="create-performer-name"
                placeholder="Enter Performer's Name"
                onChange={handleInputChange}
                required
              />

              <input
                type="text"
                value={newPerformer.description}
                name="description"
                id="create-performer-description"
                placeholder="Enter Performer's Description"
                onChange={handleInputChange}
                // required
              />
              <input
                type="email"
                value={newPerformer.email}
                name="email"
                id="create-performer-email"
                placeholder="Enter Performer's Email"
                onChange={handleInputChange}
              />
              <input
                type="url"
                value={newPerformer.fb}
                name="fb"
                id="create-performer-fbLink"
                placeholder="Enter Performer's Facebook Link"
                onChange={handleInputChange}
                // required
              />
              <input
                type="url"
                value={newPerformer.youtube}
                name="youtube"
                id="create-performer-youtube"
                placeholder="Enter Performer's YouTube Link"
                onChange={handleInputChange}
                // required
              />
              <input
                type="url"
                value={newPerformer.instagram}
                name="instagram"
                id="create-performer-instagram"
                placeholder="Enter Performer's Instagram Link"
                onChange={handleInputChange}
                // required
              />
              <textarea
                value={newPerformer.bio}
                name="bio"
                id="create-performer-bio"
                placeholder="Enter Performer's Bio"
                onChange={handleInputChange}
                // required
              />
              <button
                type="submit"
                className="btn btn-secondary btn-block mt-3"
              >
                Create
              </button>
            </form>
          </div>
        </div>
      </div>

      {/* <div className="performers-list-container">
        <form onSubmit={submitSearch} className="assets-table-body">
        <h2>Search Performer</h2>
          <input ref={performerSearchRef} type="email" required/>
          <button className="btn btn-dark" type="submit">Search</button>
        </form>
      </div> */}

      {/* <div className="performers-list-container">
        <>
          <div className="assets-table-body">
            <div>
              <h2>Update Performer</h2>
              <form
                onSubmit={submitCreateOrUpdatePerformer}
                className="create-performer"
              >
                <input
                  type="text"
                  value={newPerformer.name}
                  name="name"
                  id="create-performer-name"
                  placeholder="Enter Performer's Name"
                  onChange={handleInputChange}
                  required
                />
                <input
                  type="text"
                  value={newPerformer.description}
                  name="description"
                  id="create-performer-description"
                  placeholder="Enter Performer's Description"
                  onChange={handleInputChange}
                  required
                />
              

                <input
                  type="email"
                  value={newPerformer.email}
                  name="email"
                  id="create-performer-email"
                  placeholder="Enter Performer's Email"
                  onChange={handleInputChange}
                  disabled
                />
                <input
                  type="url"
                  value={newPerformer.fb}
                  name="fb"
                  id="create-performer-fbLink"
                  placeholder="Enter Performer's Facebook Link"
                  onChange={handleInputChange}
                  required
                />
                <input
                  type="url"
                  value={newPerformer.youtube}
                  name="youtube"
                  id="create-performer-youtube"
                  placeholder="Enter Performer's YouTube Link"
                  onChange={handleInputChange}
                  required
                />
                <input
                  type="url"
                  value={newPerformer.instagram}
                  name="instagram"
                  id="create-performer-instagram"
                  placeholder="Enter Performer's instagramgram Link"
                  onChange={handleInputChange}
                  required
                />
                <textarea
                  value={newPerformer.bio}
                  name="bio"
                  id="create-performer-bio"
                  placeholder="Enter Performer's Bio"
                  onChange={handleInputChange}
                  required
                />
               
                <button
                  type="submit"
                  className="btn btn-secondary btn-block mt-3 me-3"
                >
                  Update
                </button>
              </form>
            </div>
          </div>
        </>
      </div> */}
    </div>
  );
};

export default Perfomer;
